import React, {useContext} from 'react';
import Menu from "../../components/menu/menu";
import {
    Main,
    Title,
    SubtitleTop,
    SubtitleBottom,
    Buttons,
    Button,
    Wrapper,
    CloseButton,
    MobileCloseButton,
    MobileCloseButtonInner, MobileCloseButtonWrapper, ButtonDisabled, Disclaimer, DisclaimerBottom
} from "./tickets.styles";
import {ResponsiveContext} from "../../utils/responsive";


function Tickets() {

    return (
        <div>
            <Menu/>
            <Wrapper>
                <Main>
                    <CloseButton href="/">x</CloseButton>
                    <Title>Admission to Casa Bonita is by Ticket Only</Title>
                    <SubtitleTop>We do not accept walk-ins.</SubtitleTop>
                    <Disclaimer>We’re looking forward to bringing you the Casa Bonita experience!<br /><br />
                        We are currently open for dinner service Tuesday through Saturday, and we also offer lunch service Thursday through Sunday.<br /><br />
                    </Disclaimer>
                    <SubtitleBottom>Please choose your party size below.</SubtitleBottom>
                    <Buttons>
                        <Button href="/newsletter" target="_blank">
                            <span>1 - 10 PEOPLE</span>
                        </Button>
                        <ButtonDisabled>
                            <span>Group Events</span>
                        </ButtonDisabled>
                    </Buttons>
                    <DisclaimerBottom>
                      Unfortunately, we are currently sold out for Group Events in 2024 and are not accepting any new requests at this time.
                    </DisclaimerBottom>
                </Main>
            </Wrapper>
            <MobileCloseButtonWrapper href="/">
                <MobileCloseButton>
                    <MobileCloseButtonInner href="/">Close</MobileCloseButtonInner>
                </MobileCloseButton>
            </MobileCloseButtonWrapper>
        </div>
    );
}

export default Tickets;
