import styled from 'styled-components'
import {media, mediaDesktop, mediaMobile, mediaSmallMobile, mediaTablet} from '../../utils/media-queries'

export const Main = styled.div`
  background-image: url(/images/table-menus/background.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  
    ${mediaDesktop} {
        padding-top: 10rem;
    }
    ${mediaTablet} {
        padding-top: 10rem;
    }
    ${mediaMobile} {
        padding-top: 2rem;
    }
    ${mediaSmallMobile} {
        padding-top: 2rem;
    }
`;

export const Top = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
`;

export const Bottom = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
`;

export const DrinksTitle = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
    ${mediaDesktop} {
        padding-top: 10rem;
    }
    ${mediaTablet} {
        padding-top: 10rem;
    }
    ${mediaMobile} {
        padding-top: 3rem;
    }
    ${mediaSmallMobile} {
        padding-top: 3rem;
    }
`;

