import React from 'react';
import Menu from "../../components/menu/menu";
import Footer from "../../components/footer/footer";
import {} from "./press-signup.styles";
import Newsletter from "../../components/newsletter/newsletter";


function PressSignup() {
    return (
        <div>
            <Menu/>
                <Newsletter type="press"/>
            <Footer/>
        </div>
    );
}

export default PressSignup;