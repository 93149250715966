import styled from 'styled-components'

export const Root = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const MenuImage = styled.img`
  width: inherit;
  z-index: 0;
`

export const MenuButton = styled.a`
  position: absolute;
  width: ${({ width }) => width * 100}%;
  height: ${({ height }) => height * 100}%;
  top: ${({ y }) => y * 100}%;
  left: ${({ x }) => x * 100}%;
  border: none;
  cursor: pointer;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  background: transparent;
  z-index: 1;
`
