import React from 'react';
import {Main, Slider, ImageContainer, Image} from './photogallery.styles';
import { Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import { Autoplay, EffectFade, Navigation } from 'swiper'

const showFrame = false;
const images = [
    {"src": process.env.PUBLIC_URL + "/images/photogallery/Polaroids-EatWatchDiveShow.png",
    "title": "A", "meta": "EAT AND WATCH THE DIVE SHOW"},
    {"src": process.env.PUBLIC_URL + "/images/photogallery/Polaroids-DeliciousMexicanFoodREVISED.png",
        "title": "b", "meta": "DELICIOUS MEXICAN FOOD"},
    {"src": process.env.PUBLIC_URL + "/images/photogallery/Polaroids-CraftCocktailsREVISED.png",
        "title": "C", "meta": "SPECIALTY CRAFT COCKTAILS"},
    {"src": process.env.PUBLIC_URL + "/images/photogallery/Polaroids-DeliciousDesserts.png",
        "title": "D", "meta": "DELICIOUS DESSERTS"},
    {"src": process.env.PUBLIC_URL + "/images/photogallery/Polaroids-BlackBart'sCave.png",
        "title": "E", "meta": "BLACK BART'S MYSTERIOUS CAVE"},
];
const Photography = ({ image, index = 0, showFrame = false }) => {
    return (
        <ImageContainer className={`ImageContainer image${index}`} $showFrame={showFrame}>
            <img src={image.src} alt={image.title} aria-label={image.meta} width={900} height={500} />
        </ImageContainer>
    )
}
export default class PhotoGallery extends React.Component {

    render() {
        return (
            <Main>
                <Slider $showFrame={showFrame}>
                    <Swiper
                        navigation={true}
                        effect={'fade'}
                        spaceBetween={50}
                        slidesPerView={1}
                        modules={[Autoplay, EffectFade, Navigation]}
                        onSlideChange={() => {}}
                        onSwiper={(swiper) => {}}
                        loop={true}
                        autoplay={{
                            delay: 3500,
                        }}
                        loopedSlides={images.length}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <Photography image={image} index={index % 7} showFrame={showFrame} />
                            </SwiperSlide>
                        ))}
                        {images.map((image, index) => (
                            <SwiperSlide key={index + images.length}>
                                <Photography
                                    image={image}
                                    index={(index + images.length) % 7}
                                    showFrame={showFrame}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Slider>
            </Main>
        );
    }
}