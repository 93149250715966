import styled from 'styled-components'
import {media, mediaDesktop, mediaMobile, mediaTablet} from '../../utils/media-queries'

export const Spacer = styled.div`
    ${mediaDesktop}{
      margin-top: 10rem;
    }
    ${mediaTablet}{
      margin-top: 10rem;
    }
    ${mediaMobile}{
      margin-top: 5rem;
    }
`
