import { isString } from 'lodash-es'

const typeGenerator = ({
                           fontFamily,
                           size = 16,
                           weight = 400,
                           lineHeight = '100%',
                           letterSpacing = 0,
                           uppercase = false,
                           italic = false,
                           antialias = false,
                       }) => `
    font-family: ${fontFamily};
    font-size: ${size / 10}rem;
    font-weight: ${weight};
    line-height: ${lineHeight};
    letter-spacing: ${isString(letterSpacing) ? letterSpacing : `${letterSpacing / 10}rem`};
    ${uppercase ? 'text-transform: uppercase;' : ''}
    ${italic ? 'font-style: italic;' : ''}

    ${
    antialias
        ? `
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    `
        : ''
}

`

/* Value Sans types */

const typeValueGenerator = (attributes) =>
    typeGenerator({
        fontFamily: `Value Sans, sans-serif`,
        antialias: attributes.size < 20,
        ...attributes,
    })

export const typeValueS76W900 = typeValueGenerator({ size: 76, weight: 900 })
export const typeValueS76W500 = typeValueGenerator({ size: 76, weight: 500 })

export const typeValueS55W900 = typeValueGenerator({
    size: 55,
    weight: 900,
    letterSpacing: '0.08em',
})

export const typeValueS50W700 = typeValueGenerator({
    size: 50,
    weight: 700,
    letterSpacing: '0.08em',
})

export const typeValueS50W500 = typeValueGenerator({
    size: 50,
    weight: 500,
    letterSpacing: '0.04em',
})

export const typeValueS44W500 = typeValueGenerator({
    size: 44,
    weight: 500,
    letterSpacing: '0.07em',
})

export const typeValueS40W700 = typeValueGenerator({
    size: 40,
    weight: 700,
    letterSpacing: '0.08em',
})

export const typeValueS36W500 = typeValueGenerator({
    size: 36,
    weight: 500,
    letterSpacing: '0.07em',
})

export const typeValueS30W900 = typeValueGenerator({ size: 30, weight: 900 })
export const typeValueS30W500 = typeValueGenerator({ size: 30, weight: 500 })

export const typeValueS28W700 = typeValueGenerator({
    size: 28,
    weight: 700,
    lineHeight: '115%',
    letterSpacing: '0.08em',
})

export const typeValueS24W500 = typeValueGenerator({
    size: 24,
    weight: 500,
    letterSpacing: '0.125em',
})

export const typeValueS20W900 = typeValueGenerator({
    size: 20,
    weight: 900,
    letterSpacing: '0.08em',
})

export const typeValueS20W500 = typeValueGenerator({
    size: 20,
    weight: 500,
    letterSpacing: '0.06em',
})

export const typeValueS16W700 = typeValueGenerator({
    size: 16,
    weight: 700,
    letterSpacing: '0.05em',
})

export const typeValueS16W500 = typeValueGenerator({
    size: 16,
    weight: 500,
    letterSpacing: '0.07em',
})

/* Gooper types */

const typeGooperGenerator = (attributes) => typeGenerator({ fontFamily: `Gooper`, ...attributes })

export const typeGooperS215W600Italic = typeGooperGenerator({
    size: 215,
    weight: 600,
    italic: true,
})

export const typeGooperS50W800Italic = typeGooperGenerator({
    size: 50,
    weight: 800,
    italic: true,
})

/* New Spirit types */

const typeNewSpiritGenerator = (attributes) =>
    typeGenerator({ fontFamily: `'new-spirit', serif`, ...attributes })

export const typeNewSpiritS105W700 = typeNewSpiritGenerator({
    size: 105,
    weight: 700,
    letterSpacing: '-0.035em',
    lineHeight: '82%',
})

export const typeNewSpiritS70W300 = typeNewSpiritGenerator({
    size: 70,
    weight: 300,
    lineHeight: '110%',
    letterSpacing: '0.01em',
})

export const typeNewSpiritS58W600 = typeNewSpiritGenerator({
    size: 58,
    weight: 600,
})

export const typeNewSpiritS46W700 = typeNewSpiritGenerator({
    size: 46,
    weight: 700,
    lineHeight: '82%',
    letterSpacing: '-0.035em',
})

export const typeNewSpiritS40W600 = typeNewSpiritGenerator({
    size: 40,
    weight: 600,
    lineHeight: '82%',
    letterSpacing: '-0.035em',
})

export const typeNewSpiritS32W600 = typeNewSpiritGenerator({
    size: 32,
    weight: 600,
    lineHeight: '82%',
    letterSpacing: '-0.035em',
})

export const typeNewSpiritS26W600 = typeNewSpiritGenerator({
    size: 26,
    weight: 600,
})

export const typeNewSpiritS24W700 = typeNewSpiritGenerator({
    size: 24,
    weight: 700,
})

export const typeNewSpiritS22W600 = typeNewSpiritGenerator({
    size: 22,
    weight: 600,
})

export const typeNewSpiritS17W600 = typeNewSpiritGenerator({
    size: 17,
    weight: 600,
})

/* ITC Caslon */

const typeITCCaslonGenerator = (attributes) =>
    typeGenerator({ fontFamily: `ITC Caslon`, ...attributes })

export const typeITCCaslonS210W700Italic = typeITCCaslonGenerator({
    size: 210,
    weight: 700,
    italic: true,
    lineHeight: '72%',
    letterSpacing: '-0.05em',
})

export const typeITCCaslonS120W700Italic = typeITCCaslonGenerator({
    size: 120,
    weight: 700,
    italic: true,
    lineHeight: 144 / 120,
    letterSpacing: '-0.04em',
})

export const typeITCCaslonS56W700Italic = typeITCCaslonGenerator({
    size: 56,
    weight: 700,
    italic: true,
    lineHeight: '108%',
    letterSpacing: '-0.04em',
})