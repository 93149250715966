import React from 'react';
import {
    SectionPriceContainer,
    SectionPriceImage,
    SectionPriceImageContainer,
    SectionPriceText
} from "./section-price.styles";

function SectionPrice({ price }) {
    return (
        <SectionPriceContainer>
            <SectionPriceImageContainer>
                <SectionPriceImage src="/images/table-menus/section-price.png" />
                <SectionPriceText>${price}</SectionPriceText>
            </SectionPriceImageContainer>
        </SectionPriceContainer>
    );
};

export default SectionPrice;