import React from 'react';
import Menu from "../../components/menu/menu";
import Footer from "../../components/footer/footer";
import {Spacer} from "./menu.styles";
import SplitMenu from "../../components/splitmenu/splitmenu"

function MenuPage() {
    return (
        <div>
            <Menu/>
                <Spacer/>
                <SplitMenu pageType="menu"/>
            <Footer/>
        </div>
    );
}

export default MenuPage;
