import styled from 'styled-components';

export const Container = styled.div`
    background-image: url(/images/tablemenu/CB_QR_Drink_Menu_9.14.23.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    height: calc(100vw / (640 / 6463));
`

function QRdrinks() {
    return (
        <div>
        <Container aria-label="QRdrinks" role="img"></Container>
        </div>
    );
}

export default QRdrinks;

