import axios from 'axios';

async function fetchMenuData() {
    try {
        const response = await axios.get('https://wp.casabonitadenver.com/wp-json/wp/v2/pages/328');
        return response.data;
    } catch (error) {
        console.error('Error fetching menu data:', error);
        throw error;
    }
}

function parseMenuData(rawData) {
    let data = rawData["acf"]["sections"];
    console.log(data);
    return data;
}

export { fetchMenuData, parseMenuData };