import styled from 'styled-components';
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

export const Main = styled.div`
    background: #47143f;;
    padding: 8rem 80px 4rem;
    ${mediaDesktop} {
      padding: 10.9rem 0 0;
    }
`;
export const LogoWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  ${mediaDesktop} {
    margin-bottom: 4rem;
    padding: 0 20rem;
  }
  ${mediaTablet} {
    max-width: 90%;
  }
  ${mediaMobile} {
    margin-bottom: 2rem;
    padding-right: 2.8rem;
  }
`;
export const Logo = styled.div`
  height: 17rem;
  width: 100%;
  max-width: 15rem;
  object-fit: contain;
  object-position: center;
  ${mediaDesktop} {
    height: 15rem;
    max-width: 206rem;
    background-image: url(/images/footer-logo.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  ${mediaTablet} {
    background-image: url(/images/footer-logo.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  ${mediaMobile} {
    background-image: url(/images/footer-logo-mobile.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const Address = styled.a`
  display: block;
    color: #ffffd9;
    text-align: center;
    font-family: Value Sans,sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.07em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 2.7rem;
    text-decoration: none;
    ${mediaDesktop} {
      font-family: Value Sans,sans-serif;
      font-size: 4.4rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.07em;
      margin-bottom: 2.7rem;
    }
`;
export const Hours = styled.a`
  text-decoration: none;
  display: block;
    color: #ffffd9;
    text-align: center;
    margin-bottom: 3.6rem;
    font-family: "Value Sans", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.07em;
    -webkit-font-smoothing: antialiased;
    ${mediaDesktop} {
      font-family: "Value Sans", sans-serif;
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.125em;
    }
  ${mediaMobile} {
    display: none;
  }
`;
export const NavWrapper = styled.div`
  border-top: 0.16rem solid #ffffd9;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #ffffd9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -7rem;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Value Sans,sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.07em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  ${mediaDesktop} {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0;
    border-top: 0.3rem solid #ffffd9;
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: Value Sans,sans-serif;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.125em;
  }
`;
export const NavWrapper2 = styled.div`
  padding-bottom: 2rem;
  color: #ffffd9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -7rem;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: Value Sans,sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.07em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${mediaDesktop} {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0;
    padding-bottom: 3rem;
    font-family: Value Sans,sans-serif;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.125em;
  }
`;
export const Company = styled.div`
  width: 100vw;
  padding-top: 5rem;
  padding-left: 2rem;
  text-align: left;
  ${mediaMobile} {
    width: auto;
  }
`;
export const LinkWrapper = styled.div`
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem 1rem;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-bottom: 2rem;
  ${mediaDesktop} {
    gap: 2rem 7rem;
    border-bottom: none;
  }
  ${mediaMobile} {
    margin: 0px 0rem;
  }
`;
export const LinkHREF = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #ffffd9;
`;
export const CasaCares = styled(LinkHREF)`
`;
export const Contact = styled(LinkHREF)`
`;
export const Carrers = styled(LinkHREF)`
`;
export const BlogMain = styled(LinkHREF)`
`;
export const TermsOfUse = styled(LinkHREF)`
`;
export const PrivacyPolicy = styled(LinkHREF)`
`;
export const SocialWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem 1rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-bottom: 5rem;
    ${mediaDesktop} {
      gap: 6rem;
      padding-top: 0rem;
    }
    ${mediaMobile} {
      gap: 2rem;
      padding-bottom: 3rem;
    }
    ${mediaSmallMobile} {
      gap: 1rem;
    }
`;

export const SocialHREF = styled.a`
  cursor: pointer;
  text-decoration: none;
  width: 3rem;
  height: 3rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${mediaMobile} {
    width: 2rem;
    height: 2rem;
    display: block;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    -webkit-background-position: center;
    background-position: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
`;
export const Instagram = styled(SocialHREF)`
  background-image: url(/images/social-icons/instagram.svg);

`;
export const Twitter = styled(SocialHREF)`
  background-image: url(/images/social-icons/twitter.svg);
`;
export const Facebook = styled(SocialHREF)`
  background-image: url(/images/social-icons/facebook.svg);
`;
export const Ticktok = styled(SocialHREF)`
  background-image: url(/images/social-icons/tiktik.svg);
`;
export const Youtube = styled(SocialHREF)`
  background-image: url(/images/social-icons/youtube.svg);
`;