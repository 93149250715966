import styled from "styled-components";
import {mediaDesktop, mediaMobile, mediaSmallMobile, mediaTablet} from "../../utils/media-queries";

export const ItemGroupContainer = styled.div`
    ${mediaDesktop} {
        padding-top: 5rem;
        padding-bottom: 15rem;
    }
    ${mediaTablet} {
        padding-top: 5rem;
        padding-bottom: 10rem;
    }
    ${mediaMobile} {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    ${mediaSmallMobile} {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
`;
export const ItemName = styled.div`
    font-family: Averia;
    text-align: center;
    font-size: 10rem;
    color: #AF4434;
    ${mediaDesktop} {
        font-size: 5vw;
    }
    ${mediaTablet} {
        font-size: 5vw;
    }
    ${mediaMobile} {
        font-size: 5vw;
    }
    ${mediaSmallMobile} {
        font-size: 5vw;
    }
`;
export const ItemPrice = styled.div`
`;
export const ItemDetails = styled.div`
    font-family: Josefin;
    text-align: center;
    color: #000000;
    
    ${mediaDesktop} {
        font-size: 3.5vw;
        margin-top: 2rem;
    }
    ${mediaTablet} {
        font-size: 3.5vw;
        margin-top: 2rem;
    }
    ${mediaMobile} {
        font-size: 5vw;
        margin-top: 1rem;
    }
    ${mediaSmallMobile} {
        font-size: 5vw;
        margin-top: 1rem; 
    }
`;

export const Allergy = styled.span`
    font-size: 5rem;
    color: #A05025FF;
`;