import React from "react";
import {Spacer} from "../../pages/newsletter/newsletter-signup.styles";
import Newsletter from "../newsletter/newsletter";
import Footer from "../footer/footer";
import {ButtonRow, Items, Logo, Main} from "./mercado.styles";

function Mercado() {

    return (
        <Main>
            <Logo>
                <img src={process.env.PUBLIC_URL + '/images/mercado/mercado.png'} alt='Mercado'/>
            </Logo>
            <Items>
                <img src={process.env.PUBLIC_URL + '/images/mercado/TShirt.png'} alt='T-Shirt'/>
                <img src={process.env.PUBLIC_URL + '/images/mercado/Hat.png'} alt='Hat'/>
                <img src={process.env.PUBLIC_URL + '/images/mercado/Stickers.png'} class="onlyDesktop" alt='Stickers'/>
            </Items>
            <ButtonRow>
                <a href="https://shop.casabonitadenver.com/" target="_blank">
                <img src={process.env.PUBLIC_URL + '/images/mercado/shop-now.png'} alt='Shop Now'/>
                </a>
            </ButtonRow>
        </Main>
    );

}

export default Mercado;