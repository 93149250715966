import styled from 'styled-components';
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

export const Main = styled.div`
  outline:dotted;
  outline-offset:-10px;
  background: #733818;
  outline-color: #FDFADA;
  outline-width: 3px;
  padding: 4rem;
`;

export const Logo = styled.div`
    img {
      ${mediaMobile} {
        width: 75vw;
      }
      ${mediaDesktop} {
        width: 40vw;
      }
      ${mediaTablet} {
        width: 40vw;
      }
    }
`;

export const Items = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    gap: 4rem;
    img {
        ${mediaMobile} {
          padding-top: 5rem;
          width: 35vw;
        }
        ${mediaDesktop} {
          padding-top: 3rem;
          width: 20vw;
        }
        ${mediaTablet} {
          padding-top: 3rem;
          width: 20vw;
        }
    }
    ${mediaMobile} {
      .onlyDesktop {
        display: none;
      }
    }
`;

export const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    img {
        ${mediaMobile} {
          width: 70vw;
        }
        ${mediaDesktop} {
          width: 30vw;
        }
        ${mediaTablet} {
          width: 30vw;
        }
    }
`;