import styled from 'styled-components';
import * as t from "../../utils/typography";
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

export const Container = styled.div`
  position: relative;
  display: flex;
  min-height: 55vw;
  padding: 1rem;
  flex-direction: column;
  background: #93a63b;
  ${mediaDesktop} {
    padding: 3.3rem;
  }
  ${mediaMobile} {
    padding-bottom: 8rem;
    min-height: 67vw;
    padding-left:0;
    padding-right:0;
  }
  
`;
export const InnerContainer = styled.div`
  position: relative;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;
  ${mediaDesktop} {
    margin-bottom: 0rem;
  }
`;
export const Frame = styled.div`
  border: 1rem solid transparent;
  border-image-source: url(/images/video_border.png);
  border-image-repeat: stretch;
  border-image-slice: 150 150;
  border-image-width: 12rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  z-index: 2;
  ${mediaMobile} {
    border-width: 1rem;
    border-image-width: 2rem;
  }
  ${mediaDesktop} {
    border-width: 5rem;
    border-image-width: 10rem;
  }
  
`;

export const Content = styled.div`
  width: fit-content;
  block-size: fit-content;
  border: 1rem solid transparent;
  border-image-source: url(/images/video_border.png);
  border-image-repeat: stretch;
  border-image-slice: 150 150;
  border-image-width: 12rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  z-index: 2;
  margin: auto;
  ${mediaMobile} {
    border-width: 2rem;
    border-image-width: 10rem;
    margin-top:3rem;
    margin-bottom:3rem;
  }
  ${mediaDesktop} {
    border-width: 5rem;
    border-image-width: 10rem;
  }
  ${mediaTablet} {
    border-width: 5rem;
    border-image-width: 10rem;
  }
`;

export const Title = styled.div`
  position: relative;
  top: -3rem;
  font-size:5rem;
  font-family: "new-spirit", serif;
  font-weight: 700;
  color: #2d3151;
  ${mediaMobile} {
    top: -1rem;
    font-size:2rem;
  }
`;
export const YoutubeVideo = styled.div`
  iframe {
    width: 80vw;
    height:45vw;
  }
  ${mediaMobile} {
    padding-bottom: 3rem;
  }
`;

export const BorderLeft = styled.img`

`;
export const BorderRight = styled.img`
`;
export const BorderTop = styled.img`
`;
export const BorderBottom = styled.img`
`;
