const MENU_MOBILE_BLOCKS = [
  '/images/food-menu/mobile/1.jpg',
  '/images/food-menu/mobile/2.jpg',
  '/images/food-menu/mobile/3.jpg',
  '/images/food-menu/mobile/4.jpg',
  '/images/food-menu/mobile/5.jpg'
]

const MENU_TABLET_BLOCKS = [
  '/images/food-menu/tablet/1.jpg',
  '/images/food-menu/tablet/2.jpg',
  '/images/food-menu/tablet/3.jpg',
  '/images/food-menu/tablet/4.jpg',
  '/images/food-menu/tablet/5.jpg'
]

const MENU_DESKTOP_BLOCKS = [
  '/images/food-menu/desktop/1.jpg',
  '/images/food-menu/desktop/2.jpg',
  '/images/food-menu/desktop/3.jpg',
  '/images/food-menu/desktop/4.jpg'
]

/*
  Buttons are absolute positioned on top of the image.
  X controls the horizontal position of the button.
  Y controls the vertical position of the button.

  Range for ALL values is 0 to 1.
*/

const MENU_MOBILE_BUTTONS = [
  {
    width: 0.52,
    height: 0.023,
    x: 0.504,
    y: 0.45
  },
  {
    width: 0.52,
    height: 0.023,
    x: 0.504,
    y: 0.666
  },
  {
    width: 0.52,
    height: 0.023,
    x: 0.504,
    y: 0.918
  }
]

const MENU_TABLET_BUTTONS = [
  {
    width: 0,
    height: 0,
    x: 0.82,
    y: 0.1675
  },
  {
    width: 0.16,
    height: 0.04,
    x: 0.815,
    y: 0.67
  },
  {
    width: 0.16,
    height: 0.04,
    x: 0.82,
    y: 0.865
  }
]

const MENU_DESKTOP_BUTTONS = [
  {
    width: .15,
    height: .03,
    x: 0.217,
    y: 0.48
  },
  {
    width: 0.15,
    height: 0.03,
    x: 0.791,
    y: 0.48
  },
  {
    width: 0.18,
    height: 0.03,
    x: 0.506,
    y: 0.889
  }
]

export const BREAKPOINT_MENU_CONFIG = {
  xs: { menuBlocks: MENU_MOBILE_BLOCKS, buttons: MENU_MOBILE_BUTTONS },
  sm: { menuBlocks: MENU_MOBILE_BLOCKS, buttons: MENU_MOBILE_BUTTONS },
  md: { menuBlocks: MENU_TABLET_BLOCKS, buttons: MENU_TABLET_BUTTONS },
  lg: { menuBlocks: MENU_TABLET_BLOCKS, buttons: MENU_TABLET_BUTTONS },
  xl: { menuBlocks: MENU_DESKTOP_BLOCKS, buttons: MENU_DESKTOP_BUTTONS }
}
