import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

const StyledCallout = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  font-family: "Volkhov", serif;

  ${mediaMobile} {
    padding-top: 4rem;
    font-size: 5rem;
  }
  ${mediaTablet} {
    padding-top: 5rem;
    font-size: 13rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  ${mediaDesktop} {
    padding-top: 5rem;
    font-size: 13rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }

`;

const Callout = () => {
  const [calloutContent, setCalloutContent] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://wp.casabonitadenver.com/wp-json/wp/v2/pages/387');
        const callout = response.data.acf?.callout;
        if (callout) {
          setCalloutContent(callout);
          setShow(true);
        }
      } catch (error) {
        console.error('Error fetching callout data:', error);
      }
    };

    fetchData();
  }, []); 

  return <StyledCallout show={show}>{calloutContent}</StyledCallout>;
};

export default Callout;

