import { useState, useEffect } from 'react'
import { breakpoints } from '../utils/breakpoints'

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window
      const matchedBreakpoint = Object.entries(breakpoints).reduce(
        (acc, [key, value]) => {
          if (innerWidth >= value) return [key, value]

          return acc
        },
        []
      )

      setBreakpoint(matchedBreakpoint ? matchedBreakpoint[0] : null)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return breakpoint
}
