import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import onClickOutside from "react-onclickoutside";

import {
    Address,
    AddressContent,
    SplitMenu,
    SplitMenuContent,
    Booking,
    BookingContent,
    ElMercadoContent,
    ElMercadoLink,
    ElMercadoLinkContent,
    FAQsContent,
    FAQsLink,
    FAQsLinkContent,
    TicketContent,
    TicketLink,
    TicketLinkContent,
    MenuPageLink,
    MenuPageLinkContent,
    MenuPageContent,
    DropDown,
    HomeContent,
    HomeLink,
    HomeLinkContent,
    Main,
    MainParent,
    Menu,
    MenuContent,
    GiftCardsLink,
    GiftCardsLinkContent, GiftCardsContent,
    PrivacyLink,
    PrivacyLinkContent,
    CareersContent,
    CareersLink,
    CareersLinkContent,
    Times,
    TimesContent
} from "./menu.styles";
import { get, last } from 'lodash-es'


function Nav() {
    const colors = ['#34334B', '#56323A', '#7B393A', '#A54139', '#BD4639', '#E17C42']
    const [menuVisibility, setMenuVisibility] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)

    Nav.handleClickOutside = () => {
        setMenuVisibility(false);
    };

    useEffect(() => {
        document.addEventListener('scroll', () => {
            setScrollPosition(window.scrollY);
        })
    });



    return (
        <MainParent>
            <Main scrollPosition={scrollPosition}>
                <Menu>
                    <MenuContent onClick={() => {
                        setMenuVisibility(!menuVisibility)
                    }}></MenuContent>
                </Menu>
                
                <Address>
                    <AddressContent aria-label="Address" href="https://goo.gl/maps/8k1UjgUMMPYbzL6H7" target="_blank">6715 W COLFAX AVE,
                        LAKEWOOD, CO 80214</AddressContent>
                </Address>
                <SplitMenu>
                    <SplitMenuContent aria-label="Food Menu Link" href="/menu">Entree Options</SplitMenuContent>
                </SplitMenu>
                {/*<Times>
                    <TimesContent aria-label="Hours" href="/tickets">OPEN 7 DAYS A WEEK FROM 11AM - 10PM</TimesContent>
                </Times> */}
                <Booking>
                    <BookingContent aria-label="Tickets" href="/tickets">GET TICKETS</BookingContent>
                </Booking>
            </Main>
            <DropDown isVisable={menuVisibility ? 'visible' : 'hidden'}>
                <HomeLink aria-label="Home" href="/">
                    <HomeLinkContent colorStart={colors[0]}
                                     colorEnd={colors[1]}>
                        <HomeContent>Home</HomeContent>
                    </HomeLinkContent>
                </HomeLink>
                <TicketLink aria-label="Get Tickets" href="/tickets">
                    <TicketLinkContent colorStart={colors[1]}
                                        colorEnd={colors[2]}>
                        <TicketContent>Get Tickets</TicketContent>
                    </TicketLinkContent>
                </TicketLink>
                <MenuPageLink aria-label="Menu" href="/menu">
                    <MenuPageLinkContent colorStart={colors[2]}
                                        colorEnd={colors[3]}>
                        <MenuPageContent>Entree Options</MenuPageContent>
                    </MenuPageLinkContent>
                </MenuPageLink>
                <CareersLink aria-label="Careers" href="/careers">
                    <CareersLinkContent colorStart={colors[3]}
                                      colorEnd={colors[4]}>
                        <CareersContent>Careers</CareersContent>
                    </CareersLinkContent>
                </CareersLink>
                <FAQsLink aria-label="FAQs" href="/faqs">
                <FAQsLinkContent colorStart={colors[3]}
                                    colorEnd={colors[4]}>
                    <FAQsContent>FAQs</FAQsContent>
                </FAQsLinkContent>
                </FAQsLink>
            </DropDown>
        </MainParent>
    );
}





const clickOutsideConfig = {
    handleClickOutside: () => Nav.handleClickOutside,
};

export default onClickOutside(Nav, clickOutsideConfig);;
