import styled from 'styled-components';

export const Container = styled.div`
  background-image: url(/images/tablemenu/CB_QR_AlaCarteMenu_6.20.23.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: calc(100vw / (1600 / 7649));
`

function QRfood() {
    return (
      <div>
      <Container aria-label="QRfood" role="img"></Container>
      </div>
    );
}

export default QRfood;