import React from 'react';
import styled from 'styled-components';
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

const ContainerMeals = styled.div`
    display: flex;
    justify-content: center;
    padding: 3%; 
    background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(/images/1200px_Background.jpg);
    ${mediaDesktop} {
        display: block;
        width: auto;
    }
    ${mediaTablet} {
        background-repeat: no-repeat;
        font-size: 1.563rem;
    }
    ${mediaMobile} {
        width: 100%;
        display: block;
    }
`
const StyledMeals = styled.div`
    padding-top: 4%;
    background-image: url(/images/1200px_OuterBorder.png);
    background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    ${mediaDesktop} {
        background-image: url(/images/1200px_OuterBorder.png);
        background-repeat: no-repeat;
        font-size: 1.563rem;
    }
    ${mediaTablet} {
        background-image: url(/images/768px_OuterBorder.png);
        background-repeat: no-repeat;
        font-size: 1.563rem;
    }
    ${mediaMobile} {
        background-image: url(/images/576px_OuterBorder.png);
        width: 94% !important;
        height: 93% !important;
        display: block;
        padding-top: 6% !important;
        padding-bottom: 0 !important;
    }
    ${mediaSmallMobile} {
        background-image: url(/images/576px_OuterBorder.png);
        width: 94% !important;
        height: 93% !important;
        display: block;
        padding-top: 6% !important;
        padding-bottom: 0 !important;
    }
`
const MealHeading = styled.text`
    font-size: 60px;
    font-family: 'Akaya Telivigala', cursive;
    color: #D84940;
    font-weight: bold;
    ${mediaDesktop} {
        font-size: 60px !important;
    }
    ${mediaTablet} {
        font-size: 60px !important;
    }
    ${mediaMobile} {
    max-with: 100% !important;
    padding: 0 !important;
}
`

const MealsAdditional = styled.div`
    color: #9D4A24; 
    width: 100%;
    height: auto;
    font-size: 1.563rem;
    font-weight: 700;
    font-family: 'Josefin Sans', sans-serif;
    padding: 2% 1% 0;
    .includes {
        padding: 2% 3% 0;
    }
    p {
    margin-top: 0!important;
    padding: 1% 3% 0 !important;
    }
    ${mediaDesktop} {
        padding: 0 !important;
        font-size: 20px;
    }
    ${mediaMobile} {
    max-with: 100% !important;
    padding: 2% 2% 0 0 !important;
}
`


const Additional = styled.div`
    color: #9D4A24;
    max-width: 100%;
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    padding: 5% 5% 0 0;
    span.chips {
        color: #4F2C2C;
        font-family: 'Playball', cursive; 
        font-size: 35px;
    }
    span.fresca {
        color: #D84940;
        font-family: 'Katibeh', cursive;
        font-size: 30px;
    }
    ${mediaDesktop} {
        max-with: 100% !important;
        span.chips {
            color: #4F2C2C;
            font-family: 'Playball', cursive; 
            font-size: 35px !important;
        }
        span.fresca {
            color: #D84940;
            font-family: 'Katibeh', cursive;
            font-size: 30px !important;
        }
    }
    ${mediaMobile} {
        max-with: 100% !important;
        span.chips {
            color: #4F2C2C;
            font-family: 'Playball', cursive; 
        }
        span.fresca {
            color: #D84940;
            font-family: 'Katibeh', cursive;
        }
    ${mediaSmallMobile} {
        padding: 1% 2% 0 2%;
    }
`

const MealItem = styled.div`
.wrapper {
    text-align: left !important;
    display: block;
  }

  .row {
    display: flex;
    flex-direction: row;
    ${mediaDesktop} {
        padding: 0 4% !important;
    }
    ${mediaMobile} {
        padding: 0 5%;
    }
  }

.column {
    flex-direction: column;
    font-family: 'Amarante', cursive;
    font-style: bold;
    padding: 1%;
}

.number-column {
    padding-right: 2%;
}

.desc-column {
    font-size: 30px !important;
    float: left;
    font-family: 'Josefin Sans', sans-serif;
    width: 100%;
    color: #4F2C2C;
    padding: 1% !important;
    ${mediaDesktop} {
        padding: 0 !important;
    }
    ${mediaTablet} {
        font-size: 25px !important;
    }
    ${mediaMobile} {
        font-size: 20px !important;
    }
  }
`
const MealTitle = styled.div`
    color: #D84940; 
    max-width: 100%;
    font-weight: 700;
    font-family: 'Katibeh', cursive;
    font-size: 40px;
    ${mediaMobile} {
        max-width: 100% !important;
        padding-top: 2%;
        font-size: 30px;
    }
`

/*-Kids Menu part-*/
const StyledNinos = styled.div`
    font-family: 'Volkhov', serif;
    padding-top: 20%;
    text-align: center;
    ${mediaDesktop} {
        display: block;
        width: auto;
        padding: 0 !important;
    }
    ${mediaTablet} {
        background-repeat: no-repeat;
    }
    ${mediaMobile} {
        padding: 7% 2% !important;
    }
    `
    const NinosBox = styled.div`
    display: center;
    max-width: 100%;
    min-height: 100%;
    background-image: url('./images/1200px_ParaLosNinos1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    padding: 1% 0 !important;
    .Ninos { 
        position: relative;
        min-width: 100%;
        min-height: 100%;
        color: #4F2C2C;
        margin-bottom: 0px !important;
        font-size: 24px ;
    }
    ul {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    ${mediaTablet} {
        .Ninos{
            position: relative;
            max-width: 100%;
            padding: 10% 0 0 0 !important;
            margin-bottom: 10px;
        }
        ul {
            position: relative;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }
    ${mediaDesktop} {
        .Ninos{
            position: relative;
            max-width: 100%;
            padding: 9% 0 11% 0 !important;
            margin-bottom: 0px !important;
        }
        ul {
            position: relative;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }
    ${mediaMobile} {
        .Ninos{
            position: relative;
            max-width: 100%;
            font-size: 18px !important;
            padding: 8% 0 9% 0 !important;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    ${mediaSmallMobile} {
        .Ninos{
            position: relative;
            max-width: 100%;
            padding: 9% 0 9% 0 !important;
            font-size: 10px !important;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }
`
const Sopaipillas = styled.div`
    font-size: 20px;
    font-family: 'Volkhov', serif;
    color: #D84940;
    max-width: 100%;
    font-weight: 700;
    .ageReq p {     
        padding: 0 !important;
        margin-top: 0!important;
    }
    .rawFood {
        padding: 2% 1% 2% 0 !important;
    }
    span.famous {
        color: #D84940;
        font-family: 'Katibeh', cursive;
        font-size: 30px;
    }
    span.sopaipillas {
        color: #4F2C2C;
        font-family: 'Playball', cursive; 
        font-size: 35px;
    }
    ${mediaTablet} {
        font-size: 20px;
        padding: 0 !important;
        .ageReq p {
            padding: 0 1% !important;
        }
        .rawFood {
            font-size: 20px;
            padding: 2% 0;
        }
        .rawFood p {
            margin-bottom: 0!important;
            padding-top: 0 !important;
        }
    }
    ${mediaDesktop} {
        padding: 2% 5% 5% 3% !important;
        .ageReq p {
            padding: 0 2% 3% 0 !important;
        }
        .rawFood {
            font-size: 17px !important;
            padding: 2% 1% 0 0 !important;
        }
        .rawFood p {
            margin-bottom: 0!important;
            padding-top: 0 !important;
        }
    }
    ${mediaMobile} {
        padding: 2% 5% 0 3% !important;
        .ageReq p {
            font-size: 15px;
            padding: 0 2% 6% 7% !important;
        }
        .rawFood {
            font-size: 12px !important;
            padding: 2% 0 0 0 !important;
        }
        .rawFood p {
            margin-bottom: 0!important;
            padding-top: 1 !important;
        }
    }
`

  export default class Meals extends  React.Component {
    render() {
        return (
            <ContainerMeals>
                <StyledMeals>
                <MealHeading>
                    <span style={{fontStyle: 'italic'}}>Meal Options</span>
                    <MealsAdditional><div className="includes">Includes your choice of any item below and choice of soft drink.
                        <p>Additional plates available a la carte for<span style={{ color: "red"}}> $??.??</span></p></div>
                    </MealsAdditional>
                </MealHeading>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/1.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                        <MealTitle>
                                Enchiladas
                        </MealTitle>
                            House made tortillas, guajillo pepper sauce, asadero cheese, served with rice, crema Mexicana, salad. Red, Green, or Christmas. 
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>

                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/2.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                        <MealTitle>
                                Carnitas
                        </MealTitle>
                            Pork, cabbage salad, lime oil dressing, green chili salsa. 
                    Served with house made tortillas on the side. 
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/3.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                        <MealTitle>
                            Adobo Chicken
                        </MealTitle>
                            House made tortillas, beans, served with Mexican rice.
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/4.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                        <MealTitle>
                                Picadillo
                        </MealTitle>
                            Ground beef and potatoes with green chili sauce, served with Mexican rice.
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/5.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                        <MealTitle>
                                Calavacitas(Veggies)
                        </MealTitle>
                            Roasted corn, squash, cauliflower, roasted poblanos, asadero cheese, served with Mexican rice.
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/6.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                        <MealTitle>
                                Camarones
                        </MealTitle>
                            Adobo marinated shrimp over rice with cabbage salad.
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/7.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                            <MealTitle>
                                Casa Bonita Mole
                            </MealTitle>
                            Shredded chicken, Oaxacan-style mole sauce with dried peppers, pumpkin and sesame seed sauce, served with rice.
                            </div>
                        </div>
                    </div>
                </div>
                        <img src="/images/1200px_Divider.png" style={{ width: "88%", padding: "1% 0 1% 0" }}/>
                </MealItem>
                <MealItem>
                <div class='wrapper'>
                    <div class='row'>
                        <div class='column'>
                            <div class='number-column'>
                            <img src="/images/8.png"/>
                            </div>
                        </div>
                        <div class='column'>
                            <div class='desc-column'>
                            <MealTitle>
                                Taco Salad
                            </MealTitle>
                            Lettuce, picadillo meat, tomatoes, cheese, tortilla strips, chipotle aoili.
                            </div>
                        </div>
                    </div>
                </div>
                </MealItem>

                <Additional>
                    <div>
                        <span>ALL MEALS SERVED WITH:</span> <span className="chips">Chips & Salsa  </span><span className="fresca">(Salsa Fresca)</span>
                    </div>
                </Additional>
                    <StyledNinos>
                        <NinosBox>
                        <div className='Ninos'>
                            <div>

                            <ul>
                                <li>Betty's Bean and Cheese Burrito</li>
                                <li>Mac n' Cheese with Chicken Tenders</li>
                                <li>Rice and Beans</li>
                                <li>Mexican Hot Dog with Fries</li>
                                <li>Mexican Burger with Fries</li>
                            </ul>
                            </div>
                        </div>
                        </NinosBox>
                        <Sopaipillas>
                        <div className='ageReq'><p><span style={{ color: "#D84940"}}>12 & under only - no substitutions</span></p>
                        <span className="famous">OUR FAMOUS</span> <span className="sopaipillas"> Sopaipillas</span>
                        </div>
                        <div><img src="/images/1200px_TextLineFrame_filled.png" style={{ width: "60%" }} />
                        <div className='rawFood'> *Some items may be served raw or uncooked or contain raw or undercooked ingredients.
                        <p>Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness.</p></div>
                        </div>
                        </Sopaipillas>
                    </StyledNinos>
                </StyledMeals>
            </ContainerMeals>
        )
    }
}