import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import { Root, Heading, Section, Revised, Tickets } from '../privacy-policy/PrivacyPolicy.styled'

export const TicketingTerms = () => (
  <div>
    <Menu />
    <Root>  
      <Tickets>Casa Bonita Ticket Purchases</Tickets>
      <Heading>Terms and Conditions</Heading>
      <Revised>LAST UPDATED: June 20, 2023</Revised>
      <Section>
      <p>These terms and conditions govern the ticketing program at the Casa Bonita restaurant located at 6715 W. Colfax Ave., Lakewood, Colorado 80214 and operated by The Beautiful Opco, LLC (“<strong>Casa Bonita</strong>," "<strong>we</strong>,"<strong>us</strong>," "<strong>our</strong>," "<strong>ours</strong>").Each individual holding a ticket for Casa Bonita is referred to as a “<strong>holder</strong>" or a "<strong>holder of a ticket</strong>" in these terms and conditions. We may amend or update these terms and conditions at any time. You can determine whether these terms and conditions have been revised since your last visit by referring to the “<strong>Last Updated</strong>" date at the top of this page.</p>

      <p>In the event of a conflict between OpenTable’s 
        {' '}
          <a href='https://www.opentable.com/legal/terms-and-conditions' target='_blank' rel='noreferrer'>
            Terms of Use
          </a> and these terms and conditions, OpenTable’s then-current Terms of Use shall control.
        </p>
      </Section>
      <Section>
        <p><strong>TICKET SALES:</strong></p>
        <p><strong>All ticket sales for Casa Bonita are final.</strong></p>
        <p>No cancellations, refunds, rain checks, transfers, or exchanges. Tickets are not redeemable for cash. Tickets may not be copied or resold and may only be used once. Unauthorized use or duplication of a ticket, in whole or in part, is strictly prohibited. In the event of duplication, we may cancel the applicable tickets without refund and eject the applicable individuals from the Casa Bonita restaurant.</p>
        <p>By purchasing a FLEX ticket, if available, if a holder is unable to visit the Casa Bonita restaurant for any reason at the time listed on the FLEX ticket, the holder may, up to 24 hours prior to the time listed on the FLEX ticket, move the visit to a future, available date and time. This option is only available to FLEX ticket holders. All other tickets are valid only for the date and time set forth on the ticket.</p>
        <p>Tickets obtained from unauthorized sources may be invalid, lost, stolen, or counterfeit and, if so, are void. Holders may not use tickets for advertising, promotion, or other trade purposes without our written consent in advance. Applicable taxes are included in the purchase price of each ticket sold through our official primary ticketing services platform.</p>
        <p>Operating dates, hours, prices, and policies of the Casa Bonita restaurant are subject to change without notice. The availability of shows and attractions at the Casa Bonita restaurant are subject to change without notice. Due to maintenance or other circumstances, certain attractions may not be open to the public. Admission to certain shows, attractions, and special events at the Casa Bonita restaurant may require an additional charge or be subject to pre-sold admission.</p>
      </Section>
      <Section>
        <p><strong>REVOCABLE LICENSE/RESTAURANT MAY REFUSE ADMISSION:</strong></p>
        <p>Each ticket is a revocable license, and admission to the Casa Bonita restaurant may be refused upon refunding the purchase price appearing thereon. In addition, we reserve the right, without the refund of any portion of the ticket purchase price, to refuse admission to or eject any person whose conduct is deemed by management to be unsafe or disorderly, who uses inappropriate, vulgar, or abusive language, who fails to comply with the Casa Bonita restaurant’s health and safety protocols or other policies, or who fails to comply with the terms and conditions set forth herein or any other Casa Bonita policy, including, without limitation, those located at</p>
        {' '}
          <a href='https://www.casabonitadenver.com/' target='_blank' rel='noreferrer'>
          https://www.casabonitadenver.com/
          </a>.
        Weapons of any kind are prohibited in the Casa Bonita restaurant. Each person entering the Casa Bonita restaurant and such person’s belongings may be searched on entry or re-entry into the Casa Bonita restaurant and by entering the Casa Bonita restaurant, each holder of a ticket consents to such searches and waives any and all claims that may arise with respect to such searches. If an individual elects not to consent to such searches, such individual may be denied entry to the Casa Bonita restaurant without refund or other compensation. If a holder wishes to leave and re-enter the Casa Bonita restaurant, such holder will be able to do so, only upon the showing of a valid ticket for the correct date and time. Re-entry to the Casa Bonita restaurant must be within three hours of holder’s initial entry time.
      </Section>
    </Root>
    <Footer />
  </div>
)
