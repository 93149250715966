import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';


const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  ${mediaMobile} {
    max-width: 80vw;
    height: auto;
  }
`
const Banner = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`
const Header = styled.div`
  max-width: 100vw;
  display: block;
  padding-top: 20rem;
  padding-bottom: 4rem;
  text-align: center;
  img {
    max-width: 95vw;
  }
  ${mediaMobile} {
  padding-top: 8rem;
  padding-bottom: 2rem;
    img {
      margin: 0 1rem;
    }
  }
  ${mediaSmallMobile} {
  padding-top: 7rem;
  padding-bottom: 1rem;
    img {
      margin: 0 0.5rem;
    }
  }
`
const Intro = styled.div`
  padding: 0 10rem 12rem;
  font-family: 'Volkhov', serif;
  font-size: 4rem;
  color: #282824;
  ${mediaMobile} {
    padding: 0 3rem 2rem;
    font-size: 1.5rem;
  }
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content; center;
  padding: 8rem 14rem 20rem 14rem;
  img.divider {
    min-width: 90vw;
    min-height: auto;
  }
  ${mediaTablet} {
  padding: 8rem 8rem 15rem 8rem;
    img.divider {
      max-width: 85vw;
      min-height: auto;
    }
  }
  ${mediaMobile} {
  padding: 0 2rem 6rem 2rem;
    img.divider{
      max-width: 90vw;
      min-height: auto;
    }
  }
  ${mediaSmallMobile} {
  padding: 0 1.5rem 5rem;
    img.divider {
      width: 85vw;
      min-height: auto;
    }
  }
`
const Block = styled.h1`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  ${mediaMobile} {
    margin: 0;
  }
  ${mediaSmallMobile} {
    padding: 0;
  }
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
  cursor: pointer;
  ${mediaSmallMobile} {
    padding: 0;
  }
`
const Question = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 4;
  padding: 7rem 5rem;
  font-family: 'Volkhov', serif;
  font-size: 4rem;
  color: #af443a;
  ${mediaMobile} {
    font-size: 1.5rem;
    padding: 2rem;
  }
  ${mediaSmallMobile} {
    font-size: 1.5rem;
    padding: 2rem;
  }
`
const ArrowRight = styled.div`
  padding: 3rem;
  img.arrow {
    max-width: 9rem;
    height: auto;
    transform: ${({ rotateArrow }) => (rotateArrow ? 'rotate(90deg)' : 'rotate(0)')};
  }
  ${mediaMobile} {
  padding: 1rem;
    img.arrow {
      max-width: 3rem;
      height: auto;
    }
  }
  ${mediaSmallMobile} {
  padding: 2rem 1rem 1rem;
    img.arrow {
      max-width: 3rem;
      height: auto;
    }
  }
`
const Answer = styled.div`
  font-size: 3rem;
  font-family: 'Volkhov', serif;
  color: #282824;
  padding: 0 15rem 5rem;
  ${mediaMobile} {
    font-size: 1.5rem;
    padding: 0 6rem;
  }
  ${mediaSmallMobile} {
    font-size: 1rem;
    padding: 0 5rem; 
  }
`
const AnswerSection = styled.div`
width: 90%;
  ${mediaMobile} {
    width:100%;
  }
  ${mediaSmallMobile} {
    width:100%;
  }

`
const AnswerHeading = styled.div`
  font-size: 4rem;
  font-weight: bold;
  font-family: 'Volkhov', serif;
  color: #282824;
  padding-bottom: 2rem;
  ${mediaMobile} {
    font-size: 1.5rem;
  }
  ${mediaSmallMobile} {
    font-size: 1.5rem;
  }

`
const AnswerBody = styled.div`
  font-size: 3rem;
  ${mediaMobile} {
    font-size: 1.2rem;
  }
  ${mediaSmallMobile} {
    font-size: 1.2rem;
  }

`
const Spacer = styled.div`
padding-top: 5rem;
${mediaMobile} {
  padding-top:2rem;
}

`



const Answers = [
  {
      question: 'Visiting',
      answer: (
        <AnswerSection>
          <AnswerHeading>Is Casa Bonita open?</AnswerHeading>
          <AnswerBody>
           Yes, Casa Bonita is open! You can get on the list to receive an invite <a href="/tickets">here</a><br /><br />
            Our hours of operation:<br />
            Tuesday and Wednesday, from 4:00pm to 10:30pm.<br />
            Thursday - Saturday, from 11:00am to 10:30pm.<br />
            Sunday, from 11:00am to 4:30pm.<br />
          </AnswerBody>
          <Spacer />
          <AnswerHeading>What’s included in my ticket?</AnswerHeading>
          <AnswerBody>
            Besides admission into Casa Bonita, each ticket includes an entree of incredible Mexican food, chips & salsa, a soft drink, and of course, sopaipillas!
            <br />Alcoholic beverages and other menu items are sold separately.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>Do kids need tickets?</AnswerHeading>
          <AnswerBody>
            Everyone must have a ticket to enter Casa Bonita. Kids aged 3 and up must purchase a ticket that includes a meal. Kids under three are free, and while no meals are included for kids under three (kids meals may be purchased when you reach your table) they must be counted in the total booking headcount.
          </AnswerBody>

          <Spacer />
          <AnswerHeading>Can minors go to Casa Bonita alone without an adult?</AnswerHeading>
          <AnswerBody>
            No. Anyone under the age of 18 must be accompanied by an adult who is at least 18 years old.
          </AnswerBody>

          <Spacer />
          <AnswerHeading>Is parking available?</AnswerHeading>
          <AnswerBody>
            We do have a parking lot adjacent to the building but we recommend considering a rideshare as parking can be tight.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>Is the time on my ticket my entry time? What do I do if I or a member of my party is running late?</AnswerHeading>
          <AnswerBody>
            Your ticket states your booking time, so please arrive promptly. Incomplete parties will not be seated so please relax by the fountain until your entire party arrives. All guests have a 15-minute grace period before they will need to be rescheduled.
          </AnswerBody>
          <Spacer />

        </AnswerSection>
      ),
      rotateArrow: false
    },
    {
      question: 'Ticketing',
      answer: (
        <AnswerSection>
          <AnswerHeading>I'm not seeing a date/time/table size that works for me. When will new reservations be available?</AnswerHeading>
          <AnswerBody>
            <ul>
              <li>If you're not able to find the availability you’re looking for while attempting to purchase your tickets, it likely means we're fully booked on that date, or we do not have a table available that can accommodate the size of your party. </li>
              <li>Please note that we are able to accommodate a maximum of ten (10) guests based on availability.</li>
              <li>When checking the site periodically, we recommend refreshing the page, clearing the cache and cookies, and/or using a desktop browser.</li>
              <li>Our booking calendar will be available in the ticket purchasing flow on our website. Check back, as cancellations occur and new tables open up regularly.</li>
            </ul>
          </AnswerBody>
          <Spacer />
          <AnswerHeading>Can I book a group event?</AnswerHeading>
          <AnswerBody>
          Unfortunately, we are currently sold out for Group Events in 2024 and are not accepting any new requests at this time.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>I am bringing children under the age of three, do I need to book a ticket for them?</AnswerHeading>
          <AnswerBody>
            Yes. When making your reservation, please remember to count all members of your party, including yourself and any children under three. You can book tables for a maximum of ten people (based on availability) and each member of your party needs a ticket, though there is no charge for children under the age of three.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>I would like to purchase Cliffside dining, but I am not seeing it on the date I am interested in.</AnswerHeading>
          <AnswerBody>
            If you cannot see a Cliffside Dining option on the date you have selected, that means it is sold out for that day. Please be aware we are not able to modify orders from Traditional to Cliffside post-purchase. If you would like to purchase our premium Cliffside Dining option, please search for another day where it is available.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>Why would I want to add the FLEX ticket to my order?</AnswerHeading>
          <AnswerBody>
            We highly recommend adding the $10 FLEX ticket just in case you are unable to make your reservation. The FLEX purchase allows you to move your tickets twice within our available booking calendar. You can move your reservation free of charge within 24 hours of your reservation. You are responsible for the difference in base ticket price if you change to a date/time with a higher price. No refunds or credits are given for changes from dates/times with higher prices to lower prices.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>We have two or more different reservations at the same time. Can we sit together?</AnswerHeading>
          <AnswerBody>
            Our seating arrangements are entirely randomized, so unfortunately, we cannot guarantee that you will be seated with or near the other party. However, once both of your parties have finished your meals, we encourage you to meet up and explore the mystery and magic of Casa Bonita together! 
          </AnswerBody>
          <Spacer />
          <AnswerHeading>What do I do if I have an extra ticket? What if I can’t make it and I need a refund?</AnswerHeading>
          <AnswerBody>
            While your reservation to Casa Bonita is nonrefundable, we are able to offer you some alternative solutions:<br />
            You can use the unused ticket(s) to order and enjoy another dish from our dinner menu for your party to share; simply inform the staff at the Ticket Plaza. If you have purchased the FLEX ticket, you can reschedule your reservation for a more convenient time. If you have not purchased the FLEX ticket, you can reach out to us at <a href="mailto:tickets@casabonitadenver.com">tickets@casabonitadenver.com</a> and we will do our best to accommodate you.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>I can’t make it due to an emergency/illness/etc, what are my options?</AnswerHeading>
          <AnswerBody>
            If you have purchased our FLEX ticket, you are able to reschedule your tickets yourself using the link at the bottom of your confirmation email. If you have not purchased the FLEX ticket, you can reach out to us at <a href="mailto:tickets@casabonitadenver.com">tickets@casabonitadenver.com</a> and we will work with you to reschedule your tickets (based on availability).
          </AnswerBody>
          <Spacer />
          </AnswerSection>

      ),
      rotateArrow: false
    },
    {
        question: 'Our Menu',
        answer: (
        <AnswerSection>         
          <AnswerHeading>Can you accommodate my allergy?</AnswerHeading>
          <AnswerBody>
          We do our best to accommodate any specific needs. Please speak with our guest service attendant at the ticketing window or with one of our servers upon ordering. Our staff is happy to answer your questions. Our menu is posted on our <a href="/menu">website</a>. We are a nut-free kitchen, and gluten free, vegetarian and vegan options have all been clearly denoted on the menu.
          </AnswerBody>
          <Spacer />
           <AnswerHeading>Do you do anything special for birthday parties? Can we bring a cake?</AnswerHeading>
          <AnswerBody>
            While we do not currently offer any specific recognition for birthdays, we are committed to making everyone's experience at Casa unforgettable. We do not allow any outside food, but we have a variety of excellent desserts available for purchase to help you celebrate, and of course, sopaipillas!
          </AnswerBody>
          <Spacer />
        </AnswerSection>
        ),
        rotateArrow: false
    },
    {
      question: 'Our Venue',
      answer: (
        <AnswerSection>
          <AnswerHeading>What should I expect when I arrive?</AnswerHeading>
          <AnswerBody>
            <ul>
              <li>Every guest must undergo a security screening upon entering the building which includes metal detectors and a bag check.</li>
              <li>No weapons of any kind are allowed in the building.</li>
              <li>Vaping or Smoking of any kind, including e-cigarettes, is not allowed.</li>
              <li>Please leave your strollers in the car, as we do not allow them inside the building.</li>
            </ul>
          </AnswerBody>
          <Spacer />
          <AnswerHeading>Should I tip during my visit?</AnswerHeading>
          <AnswerBody>
            No. Casa Bonita is a no-tipping restaurant. The service charge in our ticket price helps us compensate our team well, while ensuring our guests’ experience is without additional fees.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>What time does the entertainment occur?</AnswerHeading>
          <AnswerBody>
            All of our entertainment is offered regularly during our operating hours. No matter what time you visit, you will be able to experience all that Casa Bonita has to offer.
          </AnswerBody>
          <Spacer />
          <AnswerHeading>I lost something there, who do I contact to retrieve it?</AnswerHeading>
          <AnswerBody>
            Please reach out to <a href="mailto:hola@casabonitadenver.com">hola@casabonitadenver.com</a> and let us know the day/time you dined with us and provide a brief description of the item.
          </AnswerBody>

          
        </AnswerSection>
      ),
      rotateArrow: false
    },
    { 
      question: 'Accessibility',
      answer: (
        <AnswerSection>
          <AnswerHeading>I have a wheelchair or need mobility assistance (ADA), what do I do?</AnswerHeading>
          <AnswerBody>
            Reach out at <a href="mailto:hola@casabonitadenver.com">hola@casabonitadenver.com</a> or talk to our staff upon arrival. We’re here to help and to make all guests feel welcome!
          </AnswerBody>
          <Spacer />
          <AnswerHeading>Can I bring my service animal?</AnswerHeading>
          <AnswerBody>
            Service animals that are trained to assist an individual with a disability, in accordance with the <a href="https://www.ada.gov/resources/service-animals-faqs/" target="_blank">ADA guidelines</a>, are permitted inside Casa Bonita. For the health and safety of our guests and staff, we have a "no pets" policy, which applies to: Pets, Emotional Support Animals, Comfort Animals, and Therapy Animals.
          </AnswerBody>
          <Spacer />
        </AnswerSection>  
      ),
      rotateArrow: false 
    },
   { 
      question: 'Is Casa Bonita hiring?', 
      answer: (
          <AnswerSection>
            <AnswerBody>
              Open jobs at Casa Bonita are listed on the <a href="/careers" target="_blank" rel="noreferrer">career section</a>. Please apply directly on the job page.
            </AnswerBody>
          </AnswerSection>
        ),
      rotateArrow: false 
    },
    {
        question: 'Can Casa Bonita donate to my fundraiser?',
        answer: (
          <AnswerSection>
            <AnswerBody>
            Casa Bonita is accepting applications for donation requests. Please {' '}
            <a href="javascript:void( window.open( 'https://form.jotform.com/231308038163045', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) ">fill out the application</a> and someone will get back to you.
            </AnswerBody>
          </AnswerSection>
        ),
        rotateArrow: false
    },

];


function FAQs() {
  const [activeAnswer, setActiveAnswer] = useState(null);
  const [answers, setAnswers] = useState(Answers);
 
  const handleClick = (index) => {
    setActiveAnswer(index === activeAnswer ? null : index);
    handleRotate(index);
  };
  
  const handleRotate = (index) => {
    const updatedAnswers = answers.map((answer, i) => ({
      ...answer,
      rotateArrow: i === index ? !answer.rotateArrow : false,
      }));
  setAnswers(updatedAnswers);
  };

  // useEffect hook to scroll to the active section
  useEffect(() => {
    if (activeAnswer !== null) {
      setTimeout(() => {
        const activeSection = document.querySelector(`#section-${activeAnswer}`);
        if (activeSection) {
          window.scrollTo({
            top: activeSection.offsetTop,
            behavior: 'smooth',
          });
        }
      }, 0); // A timeout of 0 ms to ensure this runs after the DOM has been updated
    }
  }, [activeAnswer]);
  
  return (
      <div>
      <Menu></Menu>
      <Wrapper>
        <Banner>
          <Header>
          <img src={process.env.PUBLIC_URL + "/images/newsAndPress/FAQs-FramedXLarge.png"} alt="NewsPress"/>
          </Header>
        </Banner>
      </Wrapper>
      <Wrapper>
        <Container>
          <Intro>Below you’ll find answers to some of our most commonly asked questions about visiting Casa Bonita.</Intro>
          <img className="divider" src={process.env.PUBLIC_URL + "/images/newsAndPress/QuestionDivider.png"} alt="divider" />
          <Block>
          {answers.map((answer, index) => (
          <div key={index} id={`section-${index}`}>
          <Row onClick={() => handleClick(index)}>
            <Question>{answer.question}</Question>
            <ArrowRight rotateArrow={answer.rotateArrow}>
            <img className="arrow" src={process.env.PUBLIC_URL + "/images/newsAndPress/arrow-right.png"} alt="arrow" />{answer.rotateArrow}
            </ArrowRight>
          </Row>
          {index === activeAnswer && <Answer>{answer.answer}</Answer>}
          <img className="divider" src={process.env.PUBLIC_URL + "/images/newsAndPress/QuestionDivider.png"} alt="divider" />
          </div>
          ))}
          </Block>
        </Container>
      </Wrapper>
      <Footer></Footer>
      </div>
    )
  }
  
  export default FAQs;
