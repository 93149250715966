import styled from "styled-components";
import {mediaDesktop, mediaMobile, mediaSmallMobile, mediaTablet} from "../../utils/media-queries";

export const SectionPriceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const SectionPriceImageContainer = styled.div`
    position: relative;
`;
export const SectionPriceImage = styled.img`
    display: block;
    height: auto; 
    margin: auto;
    ${mediaDesktop} {
        width: 100%
    }
    ${mediaTablet} {
        width: 100%
    }
    ${mediaMobile} {
        width: 80%
    }
    ${mediaSmallMobile} {
        width: 80%
    }
`;

export const SectionPriceText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    color: #AF4434;
    font-family: Caprasimo;
    ${mediaDesktop} {
        font-size: 13rem;
    }
    ${mediaTablet} {
        font-size: 13rem;
    }
    ${mediaMobile} {
        font-size: 3rem;
    }
    ${mediaSmallMobile} {
        font-size: 3rem;
    }
`;