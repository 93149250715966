import React from 'react';
import {Main, Logo, LogoWrapper, Address,
    Hours, NavWrapper, NavWrapper2, Company, LinkWrapper, CasaCares,
    Contact, Carrers, BlogMain, TermsOfUse, PrivacyPolicy,
    SocialWrapper, Instagram, Twitter, Facebook,
    Ticktok, Youtube} from "./footer.styles";


export default class Footer extends React.Component {

    render() {
        return (
            <Main>
                <LogoWrapper>
                    <Logo></Logo>
                </LogoWrapper>
                <Address href="https://goo.gl/maps/8k1UjgUMMPYbzL6H7" target="_blank">6715 W Colfax Avenue, Lakewood, CO 80214 </Address>
                <SocialWrapper>
                    <Instagram aria-label="Instagram" href="https://www.instagram.com/casabonitaofficial" target="_blank"></Instagram>
                    <Twitter aria-label="Twitter" href="https://www.twitter.com/casabonita" target="_blank"></Twitter>
                    <Facebook aria-label="Facebook" href="https://www.facebook.com/casabonita" target="_blank"></Facebook>
                    <Ticktok aria-label="TikTok" href="https://www.tiktok.com/@casabonita" target="_blank"></Ticktok>
                    <Youtube aria-label="YouTube" href="https://www.youtube.com/@casa.bonita" target="_blank"></Youtube>
                </SocialWrapper>
                <NavWrapper>
                    <LinkWrapper>
                        <Contact aria-label="Contact" href="/contact">Contact</Contact>
                        <Carrers aria-label="Careers" href="/careers">Careers</Carrers>
                        <BlogMain aria-label="Press" href="/news">Press</BlogMain>
                        <TermsOfUse aria-label="Terms Of Use" href="/terms-of-use">Terms of Use</TermsOfUse>
                        <PrivacyPolicy aria-label="Privacy Policy" href="/privacy-policy">Privacy Policy</PrivacyPolicy>
                        <Company>©2024 The Beautiful House, LLC</Company>
                    </LinkWrapper>
                </NavWrapper>
            </Main>
        );
    }
}
