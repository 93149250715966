import React from 'react';
import styled from 'styled-components';
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

const Main = styled.div`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  ${mediaDesktop} {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  >* {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    
  }
`;
const Card = styled.div`
    aspect-ratio: 388/242;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    ${mediaDesktop} {
      aspect-ratio: 1335/784;
    }
    svg {
      position: absolute;
      top: 1.6rem;
      left: 0.8rem;
      right: 0.8rem;
      bottom: 1.6rem;
      width: calc(100% - 1.6rem);
      height: calc(100% - 2.4rem);
      display: block;
      ${mediaDesktop} {
        top: 4rem;
        left: 4rem;
        right: 4rem;
        bottom: 4rem;
        width: calc(100% - 8rem);
        height: calc(100% - 8rem);
      }
    }
`;
const CardLeft = styled(Card)`
  background: #93A632;
  color: #fff;
`;
const CardRight = styled(Card)`
  background: #985644;
  color: #E0BD42;
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
`;

const Logo = styled.img`
  ${mediaDesktop} {
    height: 12rem;
    display: block;
    width: auto;
  }
  ${mediaTablet} {
    max-width: 70%;
  }
  
  ${mediaMobile} {
    max-width: 50%;
  }
`;
const GiftCardText = styled.h3`
    font-family: "valuesans";
    font-size: 2rem;
    margin-top: 0.9rem;
    margin-bottom: 1.2rem;
    ${mediaDesktop} {
      font-size: 5.5rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  ${mediaTablet} {
    margin-bottom: 0rem;
    font-size: 4rem;
  }
  
`;

const GiftCardTextDescription = styled.h3`
    font-family: "valuesans";
    font-size: 2rem;
    margin-top: 0rem;
    margin-bottom: 1.2rem;
    ${mediaDesktop} {
      font-size: 3rem;
      margin-top: 2rem;
      margin-bottom: 5rem;
    }
  ${mediaTablet} {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;
const Button = styled.a`
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1;
  font-family: "valuesans";
  border: 0.08rem solid ${props => props.inputColor};
  border-radius: 0.7rem;
  padding: 0.7rem 4rem;
  position: relative;
  overflow: hidden;
  border-color: ${props => props.inputColor};
  text-decoration: none;
  cursor: pointer;
  ${mediaDesktop} {
    padding: 1.5rem 7rem;
    border: 0.3rem solid ${props => props.inputColor};
    font-size: 2.8rem;
  }
`;
const ButtonColor = styled.span`
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
`;
const ButtonText = styled.strong`
  position: relative;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: ${props => props.inputColor};
`;

export default class GiftCards extends React.Component {

    render() {
        return (
            <Main>
                <CardLeft>
                    <svg width="1188" height="671" viewBox="0 0 1188 671" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.5" y="1.62109" width="1185" height="37" stroke="#fff" stroke-width="3"></rect><rect x="38.5" y="1.62109" width="665.5" height="36.9999" transform="rotate(90 38.5 1.62109)" stroke="#fff" stroke-width="3"></rect><rect x="38.5" y="1.62109" width="37" height="37" transform="rotate(90 38.5 1.62109)" fill="#fff" stroke="#fff" stroke-width="3"></rect><rect x="1186.5" y="1.62109" width="37" height="37" transform="rotate(90 1186.5 1.62109)" fill="#fff" stroke="#fff" stroke-width="3"></rect><rect x="38.5" y="631.621" width="37" height="37" transform="rotate(90 38.5 631.621)" fill="#fff" stroke="#fff" stroke-width="3"></rect><rect x="1186.5" y="631.621" width="37" height="37" transform="rotate(90 1186.5 631.621)" fill="#fff" stroke="#fff" stroke-width="3"></rect><rect x="1186.5" y="1.62109" width="665.5" height="36.9999" transform="rotate(90 1186.5 1.62109)" stroke="#fff" stroke-width="3"></rect><rect x="1.5" y="631.621" width="1185" height="37" stroke="#fff" stroke-width="3"></rect></svg>
                    <Logo src={process.env.PUBLIC_URL +"/images/_images_hero-section_hero1.svg"}></Logo>
                    <GiftCardText>GIFT CARD</GiftCardText>
                    <GiftCardTextDescription>For Casa Bonita Food</GiftCardTextDescription>
                    <Button target="_blank" href="https://casabonitadenver.com" inputColor="#ffffff">
                        <ButtonColor></ButtonColor>
                        <ButtonText inputColor="#ffffff">BUY NOW</ButtonText>
                    </Button>
                </CardLeft>
                <CardRight>
                    <svg width="1188" height="671" viewBox="0 0 1188 671" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1.5" y="1.62109" width="1185" height="37" stroke="#E0BD42" stroke-width="3"></rect><rect x="38.5" y="1.62109" width="665.5" height="36.9999" transform="rotate(90 38.5 1.62109)" stroke="#E0BD42" stroke-width="3"></rect><rect x="38.5" y="1.62109" width="37" height="37" transform="rotate(90 38.5 1.62109)" fill="#E0BD42" stroke="#E0BD42" stroke-width="3"></rect><rect x="1186.5" y="1.62109" width="37" height="37" transform="rotate(90 1186.5 1.62109)" fill="#E0BD42" stroke="#E0BD42" stroke-width="3"></rect><rect x="38.5" y="631.621" width="37" height="37" transform="rotate(90 38.5 631.621)" fill="#E0BD42" stroke="#E0BD42" stroke-width="3"></rect><rect x="1186.5" y="631.621" width="37" height="37" transform="rotate(90 1186.5 631.621)" fill="#E0BD42" stroke="#E0BD42" stroke-width="3"></rect><rect x="1186.5" y="1.62109" width="665.5" height="36.9999" transform="rotate(90 1186.5 1.62109)" stroke="#E0BD42" stroke-width="3"></rect><rect x="1.5" y="631.621" width="1185" height="37" stroke="#E0BD42" stroke-width="3"></rect></svg>
                    <Logo src={process.env.PUBLIC_URL +"/images/ElMercado_Logo.png"}></Logo>
                    <GiftCardText>GIFT CARD</GiftCardText>
                    <GiftCardTextDescription>For Casa Bonita Merchandise</GiftCardTextDescription>
                    <Button target="_blank" href="https://casa-bonita-colorado.myshopify.com/collections/gift-cards" inputColor="#E0BD42">
                        <ButtonColor></ButtonColor>
                        <ButtonText inputColor="#E0BD42">BUY NOW</ButtonText>
                    </Button>
                </CardRight>
            </Main>
        );
    }
}