import { breakpoints } from './breakpoints'

export const media = (breakpoint, end) => {
    return `@media screen and (min-width: ${breakpoints[breakpoint]}px) ${
        end ? `and (max-width: ${breakpoints[end]}px)` : ''
    }`
}
export const mediaSmallMobile = media('xs', 'sm')
export const mediaMobile = media('xs', 'md')
export const mediaTablet = media('md', 'xl')
export const mediaDesktop = media('md')


/*mobile xs = 0 -576
mobile sm = 576 - 768 
mobile md = 768

tablet md = 768 - 1200 
tablet xl = 1200

desktop md = 768
*/