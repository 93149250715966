import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'

import { Root, Heading, Section, Revised } from './PrivacyPolicy.styled'

export const PrivacyPolicy = () => (
  <div>
    <Menu />
    <Root>  
      <Heading>Privacy Policy</Heading>
      <Revised>LAST UPDATED: June 20, 2023</Revised>
      <Section>
        The Beautiful House, LLC (together with each of its subsidiaries and affiliates, <strong>“Company,”</strong> <strong>“we,”</strong> or <strong>“us”</strong>) respects your privacy. This Privacy Notice describes the types of information we may
        collect from you when you visit all Company-owned physical locations, websites and domains, including CasaBonitaDenver.com or use our other services that include an authorized link to this Privacy Notice (collectively, the <strong>“Offerings”</strong>). This Privacy Notice describes (i) how we use the information we collect, (ii) with whom we share it, (iii) how we protect it, and (iv) the choices we offer you regarding our collection and use of such information.
        <strong>
        If you do not agree with this Privacy Notice, please do not access or use any part of the Offerings.
        </strong>
      </Section>
      <Section>
        <strong>1. Information We Collect and How We Collect It</strong>
        <br />
        <br />
        <u>What We Collect.</u> The personal information we collect about you
        may include, without limitation:
        <ul>
          <li>Contact information, such as name, postal address, email address, and phone number;</li>
          <li>Demographic information, such as age, date of birth, and postal code; and</li>
          <li>Payment and financial details, such as payment card number, expiration date, authentication code, billing address, and bank account number, the average value of your order via the Offerings.</li>
        </ul>
        <u>How We Collect It.</u> We may collect personal information about you, meaning information that
        can uniquely identify you, from various sources, such as:
        <ul>
          <li>Directly from you when you use the Offerings, such as when you sign up for our Casa Card loyalty program, access certain features or content, or order products;</li>
          <li>Social networks through which you may access the Offerings or otherwise interact with us; and</li>
          <li>Our parents, subsidiaries, affiliates, service providers, business partners, and other third parties.</li>
        </ul>
        <u>Automatic Collection.</u> When you use the Offerings, we may also collect certain information by automated means, such as cookies. A “cookie” is a text file that websites send to a visitor’s computer or other Internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. We may also use third-party analytics tools (such as Google Analytics) that collect information about use of the Offerings and user traffic. The information we collect by automated means may include, without limitation:
        <br />
        <ul>
          <li>Information about the devices you use to access the Offerings (such as the IP address and the type of the device, operating system,and web browser);</li>
          <li>Dates, times, and duration of use of the Offerings (including whether you are a repeat or first-time visitor, the frequency of your visits, the date of your first visit); and</li>
          <li>Information on actions taken when using the Offerings (such as
        page views and website navigation patterns).</li>
        </ul>
        The information we collect automatically is statistical data and may include personal information. It helps us to improve and personalize our Offerings, including by enabling us to identify and authenticate users.
      </Section>
      <Section>
        <strong>2. How We Respond to “Do Not Track” Signals.</strong>
        <br />
        <br />
        <em>
          A do not track signal (“DNT”) prevent web applications from tracking you, you can learn more about DNT at{' '}
          <a href='https://allaboutdnt.com' target='_blank' rel='noreferrer'>
            https://allaboutdnt.com
          </a>
        </em> .
        Your web browser may let you choose your preference as to whether you want to allow the collection of information about your online activities over time and across different websites or online services. At this time, the Offerings do not respond to the preferences you may have set in your web browser regarding such collection of your information, and the Offerings may continue to collect information in the manner described in this Privacy Notice.
      </Section>
      <Section>
        <strong>3. Third-Party Use of Cookies and Other Tracking Technologies.</strong>
        <br />
        <br />
        Some content or applications available through the Offerings, including advertisements and third-party integrations, may be served by third parties. These third parties may use cookies alone or in conjunction with other tracking technologies to collect information about you when you use the Offerings, such as your IP address, pages you visit, and when you view a page or open an email in which a tag is embedded. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content and for other purposes. We do not control these third parties’ tracking technologies or how they may be used. The information practices of these third parties are governed by their own privacy policies and are not covered by this Privacy Notice. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
      </Section>
      <Section>
        <strong>4. How We Use the Information We Collect</strong>
        <br />
        <br />
        We may use information we obtain about you to:
        <ul>
          <li>Process orders you place and complete other transactions you have requested using the Offerings, and provide you with products and services you request;</li>
          <li>Send you information or materials you request;</li>
          <li>Maintain a record of your purchases and other transactions using the Offerings;</li>
          <li>Respond to your questions and comments and provide customer support;</li>
          <li>Communicate with you about our products, services, offers, and promotions;</li>
          <li>Operate, evaluate, and improve our business and the products and services we offer;</li>
          <li>Analyze and enhance our marketing communications and strategies;</li>
          <li>Analyze trends and statistics regarding use of the Offerings and transactions conducted using the Offerings;</li>
          <li>Protect against and prevent fraud, unauthorized transactions,claims, and other liabilities, and manage risk exposure, including by identifying potential hackers and other unauthorized users;</li>
          <li>Enforce our {' '}
            <a href='/terms-of-use' target='_blank' rel='noreferrer'>
              Terms of Use
            </a> and other agreements; and
          </li>
          <li>Comply with applicable legal requirements and industry standards.</li>
        </ul>
        <u>Other Uses.</u> We also may use the information we obtain about you in other ways for which we provide specific notice at the time of collection.
      </Section>
      <Section>
        <strong>4.1. Disclosure of Information</strong>
        <br />
        <br />
        <u>Who We Disclose To.</u> We may disclose aggregated information about our users, and information that does not identify any individual user, without restriction. We may disclose personal information about you:
        <ul>
          <li>To contractors, service providers, and other third parties we use to support our business;</li>
          <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request;</li>
          <li>To enforce or apply our {' '}
            <a href='/terms-of-use' target='_blank' rel='noreferrer'>
              Terms of Use
            </a> and other agreements;
          </li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Company, users of the Offerings, or others;</li>
          <li>To third parties to market their products or services to you if you have not opted out of these disclosures; and</li>
          <li> With your consent.</li>
        </ul>
        We also reserve the right to transfer your personal information to a buyer or other transferee in the event of a merger, divestiture, restructuring, reorganization, dissolution, sale, or other transfer of some or all of our assets, equity or similar transaction. Should such a sale, merger, or transfer occur, we will use reasonable efforts to direct the transferee to use your personal information in a manner that is consistent with our Privacy Notice.
      </Section>
      <Section>
        <strong>5. Retention of Information</strong>
        <br />
        <br />
        We will keep your personal information for as long as it necessary to provide the Offerings to you. Thereafter, we will keep your personal information unless and until you exercise your rights of deletion in Section 9 below, at which time your data will be deleted or overwritten with random data.
      </Section>
      <Section>
        <strong>6. Data Security.</strong>
        <br />
        <br />
        We have implemented measures intended to protect your personal information from accidental loss and from unauthorized access, use, alteration and disclosure. All information you provide to us is stored on secure servers behind firewalls. Please note, however, that the electronic transmission of information is not completely secure. We cannot guarantee that the security measures we have in place to safeguard personal information will never be defeated or fail, or that such measures will always be sufficient or effective. Any transmission of personal information is at your own risk.
      </Section>
      <Section>
        <strong>7. Your Choices.</strong>
        <br />
        <br />
        We offer you the following choices about the information we collect from
        you and how we communicate with you:
        <br />
        <ul>
          <li><strong>Cookies</strong> Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie, and how to disable existing cookies. Please note, however, that without cookies you may not be able to take full advantage of all of the features of the Offerings.</li><br />
          <li><strong>Promotional Emails</strong> If you do not want us to use your contact information to promote our products or services, you can opt out by checking the relevant box located on the form on which we collect such information or at any other time by sending us an email stating your request to info@casabonitadenver.com. If you have received a promotional email from us, you can also opt out of receiving future promotional emails from us by following the unsubscribe instructions contained in such email.</li><br />
          <li><strong>Promotional Text Messages</strong> If you have opted into receiving promotional text messages from us, you may opt out at any time by replying “STOP” to any message, or by emailing us at info@casabonitadenver.com.</li><br />
          <li><strong>Web Analytics</strong> You may opt out of the aggregation and analysis of data collected about you on our website by Google Analytics by visiting https://tools.google.com/dlpage/gaoptout.</li><br />
          <li><strong>Disclosure of Personal Information for Third-Party Advertising</strong> If you do not want us to share your personal information with third parties for their own promotional purposes, you can opt out by emailing us at info@casabonitadenver.com.</li><br />
          <li><strong>Third-Party Tracking</strong> Although we do not control third parties’ collection or use of your information to serve interest-based advertising or other targeted content, a number of these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted advertisements from members of the Network Advertising Initiative by visiting http://www.networkadvertising.org/choices.</li>
        </ul>
      </Section>
      <Section>
        <strong>8. Accessing and Changing Your Information.</strong>
        <br />
        <br />
        You may send us an email at info@casabonitadenver.com to request access to, correct, or delete any personal information that you have provided to us. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
      </Section>
      <Section>
        <strong>9. Children Under the Age of 13.</strong>
        <br />
        <br />
        The Offerings are not directed to, and we do not knowingly collect or
        solicit personal information from, children under the age of 13. If we
        learn we have collected or received personal information from a child
        under the age of 13, we will delete that information. If you believe we
        might have any information from or about a child under the age of 13,
        please contact us at info@casabonitadenver.com.
      </Section>
      <Section>
        <strong>10. Changes to Our Privacy Notice.</strong>
        <br />
        <br />
        We reserve the right to update or change this Privacy Notice at any time. If we make a material change to our Privacy Notice, we will provide notice to you (for example, by email and/or posting on our website) that our privacy practices have changed and will provide a link to the new policy. In the event we make a material change to how we use your personal information, we will provide you with an opportunity to opt out of such new or different use. The date this Privacy Notice was last revised is at the top of this page. We encourage you to review this Privacy Notice periodically to check for any updates or changes.
      </Section>
      <Section>
        <strong>11. Data Transfers.</strong>
        <br />
        <br />
        We are based in the United States. When we obtain information about you, we may transfer, process, and store such information outside of the country in which you reside, including in the United States. By using the Offerings, you consent to the transfer to and processing and storage of your information in countries outside of your country of residence, which may have different data protection laws than those in the country where you reside.
      </Section>
      <Section>
        <strong>12. Contact Us.</strong>
        <br />
        <br />
        If you have any questions about this Privacy Notice or our information practices, please email us at info@casabonitadenver.com.
      </Section>
    </Root>
    <Footer />
  </div>
)
