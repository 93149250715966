import styled from 'styled-components';
import * as t from "../../utils/typography";
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

export const Wrapper = styled.div`
  margin-top: 15rem;
  background: linear-gradient(180deg, #322b3b 12.69%, #c64839 93.04%, #ef9848 105.33%), #2d3151;
  border-radius: 0.6rem;
  width: 100vw;
  ${mediaMobile} {
    margin: 0 auto;
    width: 95vw;
    border-radius: 2rem;
  }
  ${mediaMobile} {
    margin-top: 7rem;
  }
  ${mediaDesktop} {
    margin-bottom: 2rem;
  }
  ${mediaTablet} {
    margin-bottom: 2rem;
  }
`;

export const Main = styled.div`
  border: 3px solid var(--cream);
  width: 93vw;
  ${mediaMobile} {
    width: auto;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;
  box-shadow: 0 0 0 100rem var(--cream);
  padding: 2.2rem;
  min-height: 65rem;
  margin: 0 auto;
  outline: 3px solid var(--cream);
  outline-offset: -3rem;
  
  ${mediaDesktop} {
    padding-top: 12rem;
    padding-bottom: 19rem;
    top: 13.4rem;
    left: 4rem;
    right: 4rem;
    bottom: 4rem;
    border-radius: 2.7rem;
  }
  ${mediaMobile} {
    padding-bottom: 5rem;
    min-height: 50rem;
  }
`;
export const Title = styled.h2`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 5rem;
  font-family: var(--font-four);
  color: var(--cream);
  line-height: 102%;
  ${mediaDesktop} {
    font-size: 10.5rem;
    margin-bottom: 4.5rem;
    max-width: 133.75rem;
  }
  ${mediaMobile} {
    width: 60vw;
  }
`;

export const Disclaimer = styled.div`
  text-align: center;
  color: var(--cream);
  width: 60vw;
  font-family: var(--font-secondary);
  font-size: 2.1rem;
  margin-bottom: 2rem;
  ${mediaDesktop} {
    font-size: 4rem;
    margin-bottom: 2rem;
  }
  ${mediaMobile} {
    width: 60vw;
  }
`;

export const DisclaimerBottom = styled.div`
  text-align: center;
  color: var(--cream);
  width: 60vw;
  font-family: var(--font-secondary);
  font-style: italic;
  font-size: 2.1rem;
  margin-bottom: 1rem;
  a {
    color: var(--cream);
  }
  ${mediaDesktop} {
    font-size: 3rem;
    margin-top: 5rem;
  }
  ${mediaMobile} {
    margin-top: 2rem;
    width: 60vw;
  }
`;

export const SubtitleTop = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: var(--cream);
  font-family: var(--font-secondary);
  font-size: 2.1rem;
  margin-bottom: 4rem;
  ${mediaDesktop} {
    font-size: 4.7rem;
    margin-bottom: 5rem;
  }
  ${mediaMobile} {
    width: 60vw;
  }
`;

export const SubtitleBottom = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: var(--cream);
  font-family: var(--font-secondary);
  font-size: 2.1rem;
  margin-bottom: 5rem;
  ${mediaDesktop} {
    font-size: 5rem;
    margin-bottom: 6.9rem;
  }
  ${mediaMobile} {
    width: 60vw;
  }
`;
export const Buttons = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  width: 100%;
  ${mediaDesktop} {
    gap: 11rem;
    flex-direction: row;
    justify-content: center;
  }
  ${mediaMobile} {
    width: 60vw;
  }
`;

export const Button = styled.a`

  text-decoration: none;
  font-family: var(--font-primary);
  color: var(--blue);
  padding: 0.5rem;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  transition: 0.3s all;
  background: var(--cream);
  flex: 0 0 auto;
  border-radius: 0.5rem;
  align-items: stretch;
  height: 7.9rem;
  width: 100%;
  cursor: pointer;
  ${mediaDesktop} {
    font-size: 3.6rem;
    width: 54rem;
    height: 19rem;
    border-radius: 2rem;
    font-family: var(--font-secondary);
    font-weight: bold;
  }
  span {
    display: block;
    border-radius: 0.5rem;
    border: 0.26rem solid var(--blue);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${mediaDesktop} {
      border-radius: 2rem;
      border: 0.4rem solid var(--blue);
    }
  }
  @media( hover:hover) {
    &:hover {
      background: var(--darkYellow);
      color: var(--blue);
    }
  }
  
`;

export const ButtonDisabled = styled.a`

  text-decoration: none;
  font-family: var(--font-primary);
  color: var(--blue);
  padding: 0.5rem;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  transition: 0.3s all;
  background: #999999;
  flex: 0 0 auto;
  border-radius: 0.5rem;
  align-items: stretch;
  height: 7.9rem;
  width: 100%;
  cursor: pointer;
  ${mediaDesktop} {
    font-size: 3.6rem;
    width: 54rem;
    height: 19rem;
    border-radius: 2rem;
    font-family: var(--font-secondary);
    font-weight: bold;
  }
  span {
    display: block;
    border-radius: 0.5rem;
    border: 0.26rem solid var(--blue);
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${mediaDesktop} {
      border-radius: 2rem;
      border: 0.4rem solid var(--blue);
    }
  }
  
  
`;

export const CloseButton = styled.a`
  
  ${mediaMobile} {
    display: none;
  }
  
  position: absolute;
  top: 25rem;
  right: 15rem;
  width: 5.5rem;
  height: 5.5rem;
  background: var(--cream);
  border-radius: 1.7rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  color: transparent;
  &:before {
    background: url(/images/closeButton.svg) no-repeat center center;
    background-size: 2rem auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: all 0.3s;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    border-radius: 1.5rem;
    border: 0.4rem solid var(--blue);
    display: block;
  }
  &:hover {
    background: var(--blue);
    &:before {
      filter: invert(1);
    }
  }
${mediaMobile} {
    top: 11rem;
    right: 5rem;
}
`
export const MobileCloseButtonWrapper = styled.div`
  ${mediaDesktop || mediaTablet} {
        display: none;
  }
    ${mediaMobile} {
      width: 92vw;
      margin: 0 auto;
      padding-top: 1.1rem;
    }
`

export const MobileCloseButton = styled.div`
  font-family: var(--font-primary);
  background: var(--grey);
  color: var(--cream);
  text-transform: uppercase;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-size: 1.7rem;
  display: flex;
  transition: 0.3s all;

  flex-grow: 1;

  ${mediaDesktop} {
    background: var(--blue);
    font-size: 2.6rem;
    border-radius: 0.7rem;
    padding: 0.6rem;
  }

  &.btn {
    cursor: pointer;
    :hover,
    &:active {
      background: #af443a;
    }
  }

  ${mediaMobile} {

    }
}
`
export const MobileCloseButtonInner = styled.a`
  text-decoration: none;
  color: var(--cream);
  border: 0.2rem solid var(--cream);
  padding: 0.2rem 0.5rem;
  display: block;
  border-radius: 0.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${mediaDesktop} {
    border: 0.4rem solid var(--cream);
    border-radius: 0.7rem;
  }
`
