import {React, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import styled from 'styled-components'
import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';
import { parseISO, format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { getBlogPostBySlug, getAllBlogPosts } from '../../utils/api.js';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`
const News = styled.div`
  max-width: 100vw;
  display: block;
  padding: 20rem 0 4rem;
  text-align: center;
  img{
    max-width: 95vw;
  }
  ${mediaMobile} {
    padding: 8rem 0 2rem;
    img{
      min-width: 93vw;
    }
  }
  ${mediaSmallMobile} {
    padding: 7rem 0 2rem;
    img{
      max-width: 75vw;
    }
  }
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  ${mediaMobile} {
    flex-direction: column;
  }
  ${mediaSmallMobile} {
    flex-direction: column;
  }
`
const PressKit = styled.div`
  margin-left: auto;
  img.pressKit {
    padding: 5rem 2rem 0 0;
    justify-content: flex-end;
  }
  ${mediaMobile} {
    img.pressKit {
      max-width: 30vw;
      padding: 0 1rem 0 0;
    }
  }
  ${mediaSmallMobile} {
    img.pressKit {
      max-width: 25vw;
      padding-right: 0 1rem 0 0;
    }
  }
`
const Intro = styled.div`
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem 0 10rem;
  font-size: 3rem;
  color: #2d3151;
  font-family: 'Roboto Mono', monospace;
  ${mediaTablet} {
    font-size: 2.5rem;
    padding: 0 4rem 0 10rem;
  }
  ${mediaMobile} {
    font-size: 1rem;
    padding: 0 2rem; 
  }
  ${mediaSmallMobile} {
    font-size: 1rem;
    padding: 0 2rem; 
  }
`
const Credits = styled.div`
  text-align: center;
  align-items: center;
  font-size: 3rem;
  padding: 1rem 0 0 0;
  color: #2d3151;
  font-family: 'Roboto Mono', monospace;
  ${mediaTablet} {
    font-size: 2.5rem;
    padding: 0.6rem 0 0;
  }
  ${mediaMobile} {
    font-size: 1rem;
    padding: 0.5rem 8rem;
  }
  ${mediaSmallMobile} {
    font-size: 0.8rem;
    padding: 0.2rem 4rem;
  }
`
const StaticImg = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  ${mediaDesktop} {
  padding: 6rem 0;
  img {
    width: 65vw;
    height: auto;
  }
}
  ${mediaMobile} {
    padding: 2rem 0;
    img {
      max-width: 75vw;
      height: auto;
    }
  }

`
const LoadMore = styled.h1`
  text-align: center;
  margin-bottom: 20rem;
  ${mediaMobile} {
    margin-bottom: 8rem;
  }
`
const Button = styled.a`
  width: auto;
  flex: auto;
  font-family: "valuesans";
  color: #ffffd9;
  padding: 0.1rem;
  font-size: 1.8rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #2D3151;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0.4rem;
  font-size: 2rem;
  border-radius: 0.43rem;
  text-decoration:none;
  ${mediaDesktop} {
    padding: 0.1rem;
    font-size: 4rem;
    border: 0.5rem solid #2D3151;
    border-radius: 1rem;
  }
`
const ButtonText = styled.span`
  display: block;
  border: 0.2rem solid #ffffd9;
  padding: 1rem 7rem;
  border: 0.2rem solid #ffffd9;
  border-radius: 0.43rem;
  ${mediaDesktop} {
    padding: 2.3rem 11rem;
    border: 0.2rem solid #ffffd9;
    border-radius: 1rem;
  }
`
const Title = styled.div`
  font-size: 3rem;
  font-weight: 600;
  ${mediaTablet} {
    font-size: 2.5rem;
  }
  ${mediaMobile} {
    font-size: 0.8rem;
  }
  ${mediaSmallMobile} {
    font-size: 0.7rem;
  }
`
const Date = styled.div`
  font-size: 3rem;
  font-weight: 600;
  ${mediaTablet} {
    font-size: 2.5rem;
  }
  ${mediaMobile} {
    font-size: 0.8rem;
  }
  ${mediaSmallMobile} {
    font-size: 0.7rem;
  }
`
const Description = styled.div`
  font-size: 3rem;
  text-align: left;
  padding-top: 2.5rem;
  p {
    margin: 0;
  }
  ${mediaTablet} {
    padding-top: 2.5rem;
    font-size: 2.5rem;
  }
  ${mediaMobile} {
    padding-top: 0.7rem;
    font-size: 0.7rem;
  }
  ${mediaSmallMobile} {
    padding-top: 0.5rem;
    font-size: 0.6rem;

  }
`
const Thumbnail = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  text-align: center;
  ${mediaDesktop} {
  padding: 6rem 0;
    img.blog {
    width: 65vw;
    height: auto;
    }
  }
  ${mediaMobile} {
    padding: 2rem 0;
    img.blog {
      max-width: 75vw;
      height: auto;
    }
  }
`
const Image = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative; 
  display: inline-block;
`
const Tape = styled.div`
  text-align: left;
  background-image: url(/images/newsAndPress/PaperTape.png);
  background-position: center;
  background-repeat: no-repeat;
  padding: 1rem 3rem;
  margin: 0 2rem;
  z-index: 1;
  position: absolute;
  bottom: 0;
  font-family: 'Roboto Mono', monospace;
  color: #2d3151  !important;
  box-shadow: 0 3px 15px rgb(0 0 0 / 0.5);
  ${mediaDesktop} {
    background-size: cover;
  }
  ${mediaTablet} {
    background-size: cover;
    margin: 0 1rem;
    padding: 1rem 3rem;
  }
  ${mediaMobile} {
    margin: 0 0.8rem;
    padding: 0.5rem 0.8rem;
  }
  ${mediaSmallMobile} {
    margin: 0 0.7rem;
    padding: 0.4rem 0.8rem;
  }
`

function BlogMain() {
  const [posts, setPosts] = useState([]);
  const [staticPost, setStaticPost] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const allPosts = await getAllBlogPosts();
        setPosts(allPosts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const staticPost = posts.find((post) => post.slug === 'fun-facts-about-casa-bonita');
    setStaticPost(staticPost);
  }, [posts]);

  const [visible, setVisible] = useState(staticPost ? 1 : 0);

  const loadMore = () => {
    setVisible((visible) => visible + 1);
  };

  const BlogImages = ({ post, index = 0 }) => {
    const [featuredMedia, setFeaturedMedia] = useState();

    useEffect(() => {
      const fetchFeaturedMedia = async () => {
        try {
          const media = await getBlogPostBySlug(post.slug); 
          setFeaturedMedia(media.featured_media);
        } catch (error) {
          console.error('Error fetching featured media:', error);
          setFeaturedMedia(null);
        }
      };

      fetchFeaturedMedia();
    }, [post]);

    const formatDate = (dateString) => {
      const postDate = parseISO(dateString);
      return format(postDate, 'MMMM d, yyyy', { locale: enUS });
    };

    const handleImageClick = () => {};

    return (
      <Thumbnail>
      {featuredMedia && (
        <Link key={index} to={`/blog/${post.slug}`} target="_blank" rel="noopener noreferrer">
          <Image onClick={() => handleImageClick(index)}>
              <img className="blog" src={featuredMedia.source_url} alt="BlogImage" />
            <Tape>
              <Title dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
              <Date>{formatDate(post.date)}</Date>
              <Description dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
            </Tape>
          </Image>
        </Link>
        )}
      </Thumbnail>
    );
  };

  return (
    <div>
      <Menu></Menu>
      <Container>
        <News>
          <img src={process.env.PUBLIC_URL + '/images/newsAndPress/NewsPress-FramedXLarge.png'} alt="NewsPress" />
        </News>
      </Container>
      <FlexBox>
        <Intro><p>The Greatest Restaurant in the World! </p>
        <p>A cultural institution in the Denver area since its opening in 1974, Casa Bonita, under new ownership, is proud to open with a refreshed look and feel, and a brand new culinary team. While maintaining the magical ability to transport guests back in time to 1970s Mexico, Casa Bonita provides an unmatched experience that includes live music, shows, games and memories that will last a lifetime.</p>
        </Intro>
        <PressKit>
        <a href="javascript:void( window.open( 'https://form.jotform.com/231506971007048', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=600' ) ) "><img className="pressKit" src={process.env.PUBLIC_URL + "/images/newsAndPress/PressInquiries.png"} alt="PressKit" /></a>
        </PressKit>
      </FlexBox>
      <StaticImg><img src={process.env.PUBLIC_URL + '/images/newsAndPress/PressImage1-995x696(1).jpg'} alt="MattDanaTrey" />
      <Credits>Courtesy of Casa Bonita. More photos available in the press kit.</Credits>
      </StaticImg>
      <Thumbnail>
      {staticPost && (
        <BlogImages key={staticPost.slug} post={staticPost} index={0} />
      )}
      {posts && posts.length-1 > 0 && posts.slice(0, visible).map((post, index) => (
        <BlogImages key={index} post={post} index={index} />
      ))}
      </Thumbnail>
      <LoadMore>
      {visible < posts.length-1 && (
        <Button onClick={loadMore}>
          <ButtonText>Load more</ButtonText>
        </Button>
      )}
      </LoadMore>
      <Footer></Footer>
    </div>
  );
}

export default BlogMain;
