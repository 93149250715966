import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  ${mediaTablet} {

  }
  ${mediaMobile} {
    padding: 5rem 0 0;
  }
  ${mediaSmallMobile} {
    padding: 5rem 0 0;
  }
`
function CasaCares() {
  return (
    <div>
    <Container>
    <Menu />
    <iframe
      id="JotFormIFrame-231308038163045"
      title="Casa Cares Community Giving Application"
      onDISABLEDload="self.scrollTo(0,0)"
      allowtransparency="true"
      allowFullScreen="true"
      allow="geolocation;"
      src="https://form.jotform.com/231308038163045"
      frameBorder="0"
      style={{ width: '100%', height: '225vh', border: 'none' }}
      scrolling='no'>
    </iframe>
    
    </Container>
    <Footer />
    </div>
  );
}

export default CasaCares;