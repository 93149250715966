import './App.css'
import styled from 'styled-components'
import { mediaMobile, mediaTablet } from './utils/media-queries'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import Home from './pages/home'
/*import Press from './pages/press'*/
import ContactPage from './pages/contact/contactmain'
import FAQs from './pages/faqs/faqs'
import Tickets from './pages/tickets/tickets'
import { Careers } from './pages/careers'
import { PrivacyPolicy } from './pages/privacy-policy'
import { TermsOfUse } from './pages/terms/terms'
import { TicketingTerms } from './pages/ticketingterms/ticketingterms'
import CasaCares from "./pages/casacares/casacares"
import QRfood from "./components/QRtable/qrfood"
import QRdrinks from "./components/QRtable/qrdrinks"
import BlogMain, { blogs } from './pages/blog/blogMain'
import BlogPost from './pages/blog/blogPost'
import MenuPage from './pages/menu/menu'

import NewsletterSignup from "./pages/newsletter/newsletter-signup";
import PressSignup from "./pages/press-signup/press-signup";
import TicketsPhase1 from "./pages/ticketsPhase1/ticketsPhase1";
import DrinksMenu from "./pages/table-menus/drinks-menu";
import PDFViewer from "./pages/pdfviewier/pdfviewer";

const Spacing = styled.div`
  height: 55px;
  width: 100%;
  ${mediaTablet} {
    height: 74px;
  }
  ${mediaMobile} {
    height: 60px;
  }
`

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/tickets' element={<Tickets />} />
        <Route path='/menu' element={<MenuPage />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />:L
        <Route path='/terms-of-use' element={<TermsOfUse />} />
        <Route path='/terms-and-conditions' element={<TicketingTerms/>} />
        <Route path='/news' element={<BlogMain />} />
        <Route path='/blog/:slug' element={<BlogPost />} />
        <Route path='/casacares' element={<CasaCares />} />
        <Route path='/faqs' element={<FAQs />} />
        <Route path='/table/food' element={<QRfood />} />
        <Route path='/table/drinks' element={<QRdrinks />} />
        <Route path='/dev/table/drinks' element={<DrinksMenu />} />
        <Route path='/press-signup' element={<PressSignup />} />
        <Route path='/newsletter' element={<NewsletterSignup />} />
        <Route path='/email' element={<Navigate to="/newsletter" />} />
        <Route path='/jobs' element={<Navigate to="/careers" />} />
        <Route path='/privacy' element={<Navigate to="/privacy-policy" />} />
        <Route path='/content-release-and-waiver' element={<PDFViewer fileName="CONTENT_RELEASE_AND_WAIVER" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
