import axios from 'axios';

//from Casa WordPress BlogPosts API 
const BASE_URL = 'https://wp.casabonitadenver.com/wp-json/wp/v2/posts';

export async function getBlogPostBySlug(slug) {
  try {
    const response = await axios.get(`${BASE_URL}?slug=${slug}`);
    const postData = response.data[0];
    const mediaResponse = await axios.get(postData._links['wp:featuredmedia'][0].href);
    const media = mediaResponse.data;
    postData.featured_media = media;
    return postData;
  } catch (error) {
    console.error('Error fetching blog post:', error);
    throw error;
  }
}

export async function getAllBlogPosts() {
  try {
    const response = await axios.get(BASE_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    throw error;
  }
}

export async function getFeaturedMedia(post) {
  try {
    const featuredMediaResponse = await axios.get(post._links['wp:featuredmedia'][0].href);
    return featuredMediaResponse.data;
  } catch (error) {
    console.error('Error fetching featured media:', error);
    return null;
  }
}