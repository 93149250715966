import React, { useEffect } from 'react';

const PDFViewer = ({ fileName }) => {
  useEffect(() => {
    window.location.href = `${process.env.PUBLIC_URL}/${fileName}.pdf`;
  }, [fileName]);

  return null;
};

export default PDFViewer;
