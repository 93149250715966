import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import { Root, Heading, SubHeading, CareersIFrameWrapper } from './careers.styled.js'
import IframeResizer from '../../lib/iframe-resizer-react'
import {useRef, useState} from "react";


export const Careers = () => {

    const iframeRef = useRef(null)

  return (
    <div>
      <Menu />
      <Root id='careers-root'>
        <Heading>Careers</Heading>
          <CareersIFrameWrapper>
          <IframeResizer
              forwardRef={iframeRef}
              src="//recruitingbypaycor.com/career/CareerHome.action?clientId=8a7883c68584007b0185ad74644c1a76"
              style={{ minWidth: '100%'}}
              frameBorder="0"
          />
            </CareersIFrameWrapper>
      </Root>
      <Footer />
    </div>
  )
}