import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';
import { Helmet } from 'react-helmet'

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-height: 100vh;
  margin: 18rem 5rem 0;
  padding: 0 5rem;
  ${mediaTablet} {
    margin: 15rem 2rem 0;
  }
  ${mediaMobile} {
    margin: 6rem 0 0;
  }
  ${mediaSmallMobile} {
    margin: 6rem 0 0;
  }
`

const BoxWrapper = styled.div`
  position: relative;
  background: linear-gradient(180deg, #322b3b 12.69%, #c64839 93.04%, #ef9848 105.33%), #2d3151;
  border-radius: 3rem;
  ${mediaMobile} {
    border-radius: 0;
  }
  ${mediaSmallMobile} {
    border-radius: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
    border: 0.2rem solid var(--cream);
    border-radius: inherit;
    pointer-events: none;
    ${mediaDesktop} {
      border: 0.4rem solid var(--cream);
      border-radius: 2.7rem;
    }
    ${mediaMobile} {
      border: 0;
    }
    ${mediaSmallMobile} {
      border: 0;
    }
  }
`

function ContactForm() {
  return (
    <div>
    <Helmet>
    <meta name="robots" content="noindex" />
    </Helmet>
      <Menu/>
      <Box>
        <BoxWrapper>
        <iframe id="JotFormIFrame-230997535926067"
          title="ContactUs-CasaBonita"
          onDISABLEDload="self.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation;"
          src="https://form.jotform.com/230997535926067"
          frameborder="0"
          style={{ width: '100vw', height: '100vh', border: 'none' }}
          scrolling="no">
        </iframe>
        </BoxWrapper>
      </Box>
      <Footer/>
    </div>
  )
}

export default ContactForm;
