import styled from "styled-components";
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';
import * as t from '../../utils/typography';

export const Container = styled.div`
  background: url(/images/newsletter-section/newsletter-mobile.jpg) no-repeat;
  background-size: cover;
  ${mediaDesktop} {
    background: url(/images/newsletter-section/newsletter.jpg) no-repeat;
  }
`;
export const Box = styled.div`
  form {
    ${mediaMobile} {
      width: 100%;
      margin-top: 3rem;
    }
  }
  background: #ffffd9;
  margin: 0 auto;
  padding: 3rem 2rem;
  ${mediaDesktop} {
    padding: 9rem;
  }
  h2 {
    text-align: center;
    font-family: "new-spirit",serif;
    font-size: 4rem;
    color: #121212;
    font-style: italic;
    font-family: serif;
    line-height: 1;
    position: relative;
    margin: 0 0rem 1.6rem;
    ${mediaDesktop} {
      font-size: 9rem;
      margin: 0 5rem 6rem;
    }
    ${mediaTablet} {
      font-size: 7rem;
    }


  }
`;

export const FormInputs = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  flex-direction: column;
  ${mediaDesktop} {
    gap: 2.6rem;
    justify-content: space-between;
    flex-direction: row;
  }

  ${props => props.type === 'press' && 'flex-wrap: wrap;'};


` ;

export const Form = styled.div`
  position: relative;
  text-align: center;
  h4 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 3rem;
    letter-spacing: -4%;
    color: #121212;
    font-style: italic;
    ${mediaDesktop} {
      font-size: 5.6rem;
      margin-bottom: 10rem;
    }
  }
  .inputs {

  }
  input {
    border: none;
    background: none;
    border-bottom: 0.2rem solid #121212;
    font-size: 1.6rem;
    font-family: "valuesans";
    color: #121212;
    font-weight: 600;
    width: 100%;
    ${mediaDesktop} {
      border-bottom: 0.4rem solid #121212;
      font-size: 4rem;
    }
    &:focus {
      outline: none;
    }
  }
  button {
    ${mediaDesktop} {
      width: 40rem;
    }
    ${mediaTablet} {
      width: 40rem;
    }
    ${mediaMobile} {
        width: 20rem;
    }
    cursor: pointer;
    font-family: "new-spirit",serif;;
    background: #B24538;;
    color: #ffffd9;
    text-transform: uppercase;
    border-radius: 0.4rem;
    padding: 0.5rem;
    font-size: 1.7rem;
    display: flex;
    transition: all 0.3s ease 0s;
    -webkit-box-flex: 1;
    flex-grow: 1;
    border: none;
    ${mediaDesktop} {
      background: #B24538;;
      font-size: 2.6rem;
      border-radius: 0.7rem;
      padding: 0.6rem;
    }
    ${props => props.type === 'press' && 'margin-top: 3rem;'};
    ${props => props.type !== 'press' && 'margin-bottom: 3rem;'};
    &:hover {
      background: #121212;
      color: #fff;
    }
    ${mediaTablet} {
      ${props => props.type === 'press' && 'margin-top: 3rem;'};
      ${props => props.type === 'homepage' && 'margin-top: 0;'};
    }
    ${mediaDesktop} {
      ${props => props.type !== 'press' && 'margin-left: 6rem;'};
      font-size: 2.8rem;
      border-radius: 0.7rem;
      ${props => props.type !== 'newsletter' && 'margin-top: 3rem;'};
      ${props => props.type === 'homepage' && 'margin-top: 0;'};
    }
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  ${t.typeValueS16W500}
  ${mediaDesktop} {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 3rem;
    ${t.typeValueS30W500}
  }
`;

export const SuccessMessage = styled.div`
  color: #608f3f;
  text-align: center;
  ${t.typeValueS16W500}
  ${mediaDesktop} {
    margin-top: 3rem;
    ${t.typeValueS30W500}
  }
`;

export const ErrorSection = styled.div`
  ${mediaMobile} {
    width: 90vw;
    margin-bottom: 2.5rem;
  }
  ${mediaTablet} {
    width: 50vw;
    margin-bottom: 5rem;
  }
  ${mediaDesktop} {
    width: 50vw;
    margin-bottom: 5rem;
  }
  margin: 0 auto;
`
export const ErrorText = styled.div`
  font-size: 3rem;
  font-family: 'Averia Serif Libre';
  text-align: left;

  color: #e34d3c;
  ${mediaMobile} {
    margin-top:1rem;
    font-size: 1.5rem;
  }

`;

export const ErrorWrapper = styled.div`
  ${mediaTablet} {
    height: 3.5rem;
  }
  ${mediaDesktop} {
    height: 3.5rem;
  }
  ${mediaMobile} {
    height: auto;
  }
` ;

export const InputColumn = styled.div`
  padding: 0rem;
  width: 100%;
  ${mediaDesktop} {
    padding: 2.4rem;
  }
  ${props => props.type === 'press' && 'flex: 1 0 45%;'};
`;

export const Title = styled.div`
  width: 70%;
  margin: 0 auto;
  ${mediaMobile} {
    font-size: 3rem;
  }
`;

export const TopBottom = styled.div`
  .top {
    margin-bottom: 4.3rem;
  }
  .top,
  .botton {
    aspect-ratio: 293/16;
    background: url(/images/newletterTitleTop.svg) no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto 1.3rem;
    ${mediaDesktop} {
      width: 82rem;
      height: 4rem;
      margin: 0 auto 10rem;
    }
  }
  .botton {
    margin: 2rem auto 0;
    transform: scaleY(-1);
    ${mediaDesktop} {
      margin: 8rem auto 0;
    }
  }
    
`;

export const ButtonInside = styled.div`
  border: 0.4rem solid #ffffd9;
  border-radius: 0.7rem;
  width: 100%;
`;
