import styled from "styled-components";
import {mediaDesktop, mediaMobile, mediaSmallMobile, mediaTablet} from "../../utils/media-queries";

export const SectionNoPriceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
`;


export const SectionNoPriceImage = styled.img`
    display: block;
    height: auto; 
    margin: auto;
    ${mediaDesktop} {
        width: 80%
    }
    ${mediaTablet} {
        width: 80%
    }
    ${mediaMobile} {
        width: 80%
    }
    ${mediaSmallMobile} {
        width: 80%
    }
`;
