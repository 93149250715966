import React from 'react';
import Menu from "../../components/menu/menu";
import Footer from "../../components/footer/footer";
import {Spacer} from "./newsletter-signup.styles";
import Newsletter from "../../components/newsletter/newsletter";


function NewsletterSignup() {
    return (
        <div>
            <Menu/>
                <Spacer/>
                <Newsletter type="newsletter"/>
            <Footer/>
        </div>
    );
}

export default NewsletterSignup;