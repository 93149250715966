import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../../components/footer/footer';
import Menu from '../../components/menu/menu';
import { mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile } from '../../utils/media-queries';
import { parseISO, format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { getBlogPostBySlug } from '../../utils/api.js';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`
const News = styled.div`
  max-width: 100vw;
  display: block;
  padding-top: 20rem;
  padding-bottom: 4rem;
  text-align: center;
  img {
    max-width: 95vw;
  }
  ${mediaMobile} {
    padding-top: 8rem;
    padding-bottom: 2rem;
    img {
      min-width: 93vw;
    }
  }
  ${mediaSmallMobile} {
    padding-top: 7rem;
    padding-bottom: 2rem;
    img {
      max-width: 75vw;
    }
  }
`
const BlogImage = styled.div`
  flex: auto;
  display: block;
  text-align: center;
  ${mediaDesktop} {
  padding-bottom: 1rem;
    img {
      max-width: 70vw;
      height: auto;
    } 
  } 
  ${mediaTablet} {
  padding-top: 4rem;
    img {
      max-width: 80vw;
      height: auto;
    }
  }
  ${mediaMobile} {
  padding-top: 3rem;
    img {
      max-width: 80vw;
      height: auto;
    }
  }
`
const Title = styled.h1`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 4rem;
  margin: 0;
  padding-top: 4rem;
  ${mediaTablet} {
    font-size: 3.5rem;
  }
  ${mediaMobile} {
    font-size: 1.3rem;
    padding-top: 2.5rem;
  }
  ${mediaSmallMobile} {
    font-size: 1rem;
    padding-top: 2rem;
  }
`
const Date = styled.h2`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 5rem;
  ${mediaTablet} {
    font-size: 3.5rem;
    margin-bottom: 6rem;
  }
  ${mediaMobile} {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
  ${mediaSmallMobile} {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`
const Text = styled.div`
  font-size: 3rem;
  text-align: left;
  padding-bottom: 5rem;
  .lineBreak {
    white-space: pre-line;
  }
  ${mediaTablet} {
    font-size: 2.5rem;
  }
  ${mediaMobile} {
    font-size: 1rem;
    padding-bottom: 3rem;
  }
  ${mediaSmallMobile} {
    font-size: 0.7rem;
    padding-bottom: 2rem;
  }
}
`
const Content = styled.div`
  background-image: url('../images/newsAndPress/PaperBackground.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  text-align: left;
  height: 100%;
  width: auto;
  margin: 6rem 30rem 9rem;
  padding: 10rem 13rem;
  font-family: 'Roboto Mono', monospace;
  color: #000000;
  ${mediaTablet} {
    margin: 6rem 10rem 9rem;
    padding: 7rem 13rem;
  }
  ${mediaMobile} {
    margin: 1rem 2rem 3rem;
    padding: 3rem 4rem;
  }
  ${mediaSmallMobile} {
    margin: 1rem 2rem 3rem;
    padding: 2rem;
  }
`
const Article = styled.div`
  padding-top: 10rem;
  ${mediaTablet} {
    padding-top: 5rem;
  }
  ${mediaMobile} {
    padding-top: 0;
  }
  ${mediaSmallMobile} {
    padding-top: 0;
  }
`

function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postData = await getBlogPostBySlug(slug);
        setPost(postData);
      } catch (error) {
        setPost(null);
      }
    };

    fetchPosts();
  }, [slug]);

  if (!post) {
    return null;
  }

  const formatDate = (dateString) => {
    const postDate = parseISO(dateString);
    return format(postDate, 'MMMM d, yyyy', { locale: enUS });
  };

  return (
    <div>
      <Menu />
      <Container>
        <News>
          <img src={process.env.PUBLIC_URL + '/images/newsAndPress/NewsPress-FramedXLarge.png'} alt="NewsPress" />
        </News>
      </Container>
      <Container>
        <Article post={post}>
          <BlogImage>
            <img src={post.featured_media.source_url} alt="BlogImage" />
          </BlogImage>
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            <Date>{formatDate(post.date)}</Date>
            <Text dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </Content>
        </Article>
      </Container>
      <Footer />
    </div>
  );
}

export default BlogPost;





