import styled from 'styled-components'
import { media } from '../../utils/media-queries'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15rem;

  ${media('xs')} {
    max-width: 100%;
    margin-top: 10rem;
  }

  ${media('md')} {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15rem;
  }
`

export const Heading = styled.h3`
  font-family: new-spirit, serif;
  color: var(--brown2);
  line-height: 0;

  ${media('xs')} {
    font-size: 5rem;
  }

  ${media('md')} {
    font-size: 10rem;
  }

  ${media('xl')} {
    font-size: 11rem;
  }
`

export const SubHeading = styled.h4`
  border-top: 0.3rem solid var(--brown2);
  border-bottom: 0.3rem solid var(--brown2);
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 5rem 0rem;
  margin-top: 0;

  ${media('xs')} {
    font-size: 3rem;
  }

  ${media('xl')} {
    font-size: 8rem;
  }
`

export const CareersIFrameWrapper = styled.div`
  width: 100%;
  border: none;
  margin-bottom: 6rem;
`
