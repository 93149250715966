import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import { Root, Heading, Section, Revised } from '../privacy-policy/PrivacyPolicy.styled'

export const TermsOfUse = () => (
  <div>
    <Menu />
    <Root>
      <Heading>Terms of Use</Heading>
      <Revised>LAST REVISED: April 19, 2023</Revised>
      <Section>
        The following terms and conditions (the “Terms of Use”) constitute a binding agreement between you and The Beautiful House, LLC (together with each of its subsidiaries and affiliates, “Company,” “we,” or “us”) with respect to your use of all Company-owned websites and domains, including CasaBonitaDenver.com (collectively, the “Site”), and the products and services available on the Site (collectively, the “Services”), including any Content (as defined in Section 2 below). <strong>BY ACCESSING OR USING THE SERVICES IN ANY MANNER (WHETHER AUTOMATED OR OTHERWISE), YOU (A) ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO THESE TERMS OF USE AND OUR PRIVACY POLICY, WHICH IS INCORPORATED HEREIN BY REFERENCE, AND (B) AFFIRM THAT YOU ARE AT LEAST 18 YEARS OF AGE (OR HAVE REACHED THE AGE OF MAJORITY IN THE JURISDICTION WHERE YOU RESIDE). IF YOU DO NOT AGREE TO THESE TERMS OF USE OR OUR PRIVACY POLICY, DO NOT USE THE SERVICES.</strong>

        <p><strong>THESE TERMS OF USE INCLUDE AN AGREEMENT TO MANDATORY ARBITRATION, WHICH MEANS THAT YOU AGREE TO SUBMIT ANY DISPUTE RELATED TO THE SITE OR THE SERVICES, THESE TERMS OF USE AND/OR OUR PRIVACY POLICY TO BINDING INDIVIDUAL ARBITRATION RATHER THAN PROCEEDING IN COURT. THE DISPUTE RESOLUTION PROVISION ALSO INCLUDES A CLASS ACTION WAIVER, WHICH MEANS THAT YOU AGREE TO PROCEED WITH ANY DISPUTE INDIVIDUALLY AND NOT AS PART OF A CLASS ACTION. MORE INFORMATION ABOUT THE ARBITRATION AND CLASS ACTION WAIVER CAN BE FOUND IN SECTION 15  (ARBITRATION AGREEMENT) BELOW.</strong></p>

        <p><strong>THESE TERMS OF USE ALSO INCLUDE A JURY TRIAL WAIVER.</strong></p>
      </Section>
      <Section>
        <strong>1.  Changes to Terms of Use.</strong>We may revise and update these Terms of Use from time to time in our sole discretion. The date these Terms of Use were last updated is set forth at the top of this page. All changes are effective 14 days after posting for current users and immediately for new users, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms of Use means that you accept and agree to the changes. 
      </Section>
      <Section>
        <strong>2.  Scope of and Restrictions on Use.</strong>Subject to these Terms of Use, Company grants you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Services for your personal, non-commercial use, including any graphics, text, instructions, images, audio files and/or other sounds, videos, and other materials you may view on, access through, or are otherwise related to the Services (collectively, the “Content”). Except as otherwise provided in these Terms of Use, the Content may not be copied, downloaded, or stored in a retrieval system for any other purpose, nor may it be redistributed, reused, or modified for any purpose, without the express written permission of Company. You agree not to:
        <ul>
          <li>collect information from the Services using an automated software tool or manually on a mass basis;</li>
          <li>use automated means to access the Services, or gain unauthorized access to the Services or to any account or computer system connected to the Services;</li>
          <li>obtain, or attempt to obtain, access to areas of the Site or our systems that are not intended for access by you;</li>
          <li>“flood” the Services with requests or otherwise overburden, disrupt, or harm the Services or our systems;</li>
          <li>restrict or inhibit other users from accessing or using the Services;</li>
          <li>modify or delete any copyright, trademark, or other proprietary rights notices that appear on the Site or in the Content; or</li>
          <li>access or use the Services or Content for any unlawful purpose or otherwise beyond the scope of the rights granted herein.</li>
        </ul>
      </Section>
      <Section>
        <strong>3.  Ownership.</strong>The Services (including the Content) are owned by Company and are protected under copyright, trademark, and other applicable United States and international laws and treaties. Without limiting the foregoing, the trademarks, service marks, and logos displayed on the Site are registered and unregistered marks of Company and its licensors. You acknowledge and agree that, as between you and Company, Company is and shall remain the sole owner of the Services and the Content, including, without limitation, all patents, copyrights, trademarks, trade secrets, and other intellectual property and proprietary rights therein and thereto. 
      </Section>
      <Section>
        <strong>4.  Account Registration and Security.</strong>Access to and use of certain Services may require you to register for an account. You agree to provide true, accurate, current, and complete information about yourself as prompted by the applicable registration or log-in form, and you are responsible for keeping such information up to date. You are responsible and liable for all activities conducted through your account, regardless of who conducts those activities. You may not share your account with anyone or allow anyone else to access or use your account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to immediately notify Company of any unauthorized use of your account, or any other breach of security. We are not liable for any loss or damage arising from your failure to protect your username or password.
      </Section>
      <Section>
        <strong>5.  Electronic Communications.</strong>The communications between you and Company via the Services use electronic means. For contractual purposes, you consent to receive communications from us in electronic form, and you agree that all terms and conditions, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
      </Section>
      <Section>
        <strong>6.  Online Purchases and Other Terms and Conditions.</strong>All purchases made through the Services or other transactions for the sale of products or services formed through the Services are governed by all policies, rules, or guidelines that may be applicable to specific portions or features of the Services are incorporated into these Terms of Use. If you do not agree to be bound to such additional terms and conditions, you should not indicate any assent or acceptance of such terms and conditions.
      </Section>
      <Section>
        <strong>7.  Gift Cards.</strong>You may purchase and/or otherwise receive Company gift cards, including electronic gift cards (collectively “Gift Cards”) through the Site. All Gift Card purchases are subject to these Terms of Use. Gift Cards purchased from Company do not expire. Redemptions of Gift Cards will result in the application of a credit to your Account in the amount of the Gift Card balance. Any Gift Card balances will be applied toward any product purchased by you from Company from time to time until the Gift Card is depleted. Gift Cards are not redeemable for cash or credit, except to the extent required by applicable law. Company is not responsible for lost or stolen Gift Cards. Lost or stolen Gift Cards cannot be replaced (except as required by law). All sales of Gift Cards are final and non-refundable.
      </Section>
      <Section>
        <strong>8.  Privacy Policy.</strong>You acknowledge and agree that all information collected by Company is subject to our Privacy Policy. By using the Services, you consent to all actions we take with respect to your information in compliance with our Privacy Policy.
      </Section>
      <Section>
        <strong>9.  Sweepstakes, Contests, and Games.</strong>In addition to these Terms of Use, sweepstakes, contests, games, or other promotions (collectively, “Promotions”) made available via the Services may have specific rules that are different from these Terms of Use. We urge you to review the applicable rules before you participate in a Promotion. In the event of a conflict between these Terms of Use and Promotion rules, Promotion rules will control.
      </Section>
      <Section>
        <strong>10. Change and Suspension.</strong>
        <br />
        <br />
        10.1.   <u>Changes to the Services.</u> Company reserves the right to make changes to, suspend, or discontinue (temporarily or permanently) the Services or any portion thereof (including any Content) at any time. You agree that Company will not be liable to you or to any third party for any such change, suspension, or discontinuance.
        <br />
        <br />
        10.2.   <u>Suspension/Termination of Access.</u> Company has the right to deny access to, and to suspend or terminate your access to, the Services or to any features or portions thereof, at any time and for any reason, including if you violate these Terms of Use.  In the event that we suspend or terminate your access to the Services, you will continue to be bound by the Terms of Use that were in effect as of the date of your suspension or termination.
      </Section>
      <Section>
        <strong>11. Disclaimer; Limitation of Liability.</strong>
        <br />
        <br />
        11.1.   <u>Disclaimer of Warranties.</u> THE SERVICES AND THE CONTENT ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, AND COMPANY HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY REPRESENTATION OR WARRANTY WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES OR ANY CONTENT. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES OR THE CONTENT WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES, CONTENT, OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES OR THE CONTENT WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        <br />
        <br />
        11.1.   <u>Limitation of Liability.</u> TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL COMPANY OR ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, OR SERVICE PROVIDERS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICES OR THE CONTENT, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY OR DEATH, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF USE, OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. IF, NOTWITHSTANDING THE PROVISIONS OF THIS SECTION 11.2, COMPANY IS FOUND LIABLE FOR ANY LOSS, DAMAGE, OR INJURY UNDER ANY LEGAL THEORY RELATING IN ANY WAY TO THE SUBJECT MATTER OF THESE TERMS OF USE, IN NO EVENT WILL COMPANY’S AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY EXCEED THE TOTAL AMOUNT OF FEES, IF ANY, PAID BY YOU TO COMPANY IN THE TWELVE (12) MONTH] PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR U.S. $100.00, WHICHEVER IS LESS. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IF YOUR REMEDIES UNDER THESE TERMS OF USE FAIL OF THEIR ESSENTIAL PURPOSE. USE OF THE SERVICES IS AT YOUR SOLE RISK.
        <br />
        <br />
        11.3.   <u>Exclusions.</u> Some jurisdictions do not allow the exclusion or limitation of certain warranties or consequential damages, so some of the exclusions and/or limitations in this Section 11 may not apply to you.
      </Section>
      <Section>
        <strong>12. Indemnification.</strong>You agree to indemnify, defend, and hold Company and its officers, directors, employees, agents, licensors, and service providers harmless from and against any claims, liabilities, losses, damages, judgments, awards, costs, and expenses (including reasonable attorneys’ fees) arising out of or resulting from your use of the Services and/or any Content, or any violation of these Terms of Use or applicable law.
      </Section>
      <Section>
        <strong>13. Third Party Materials</strong>The Services may display, include, or make available third-party content (including data, information, applications and other products services and/or materials) or provide links to third-party websites or services (collectively, “Third Party Materials”). You acknowledge and agree that Company is not responsible for any Third Party Materials, including their accuracy, completeness, timeliness, validity, legality, decency, quality, or any other aspect thereof. Company does not assume and will not have any liability to you or any other person or entity for any Third Party Materials.  Third Party Materials and links thereto are provided solely as a convenience to you, and you access and use them at entirely at your own risk and subject to such third parties’ terms and conditions.
      </Section>
      <Section>
        <strong>14. Linking to the Site.</strong>You may link to the Site’s homepage, provided you do so in a way that is fair and legal and does not damage or take advantage of our reputation, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists. You must not establish a link from any website that is not owned by you. The Site must not be framed on any other website, nor may you create a link to any part of the Site other than the homepage. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.
      </Section>
      <Section>
        <strong><u>15. ARBITRATION AGREEMENT.  PLEASE READ THIS SECTION CAREFULLY.  IT AFFECTS YOUR RIGHTS AND IMPACTS THE WAY THAT YOU AND WE BRING CLAIMS AGAINST EACH OTHER AND HOW THOSE CLAIMS ARE DECIDED.</u></strong>
        <br />
        <br />
        15.1.   <u>Dispute Resolution.</u>  YOU AND WE AGREE THAT ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THE INTERPRETATION, APPLICABILITY, ENFORCEABILITY, OR FORMATION OF THESE TERMS OF USE OR YOUR USE OF THE SERVICES AND/OR CONTENT WILL BE RESOLVED EXCLUSIVELY THOUGH FINAL AND BINDING ARBITRATION, RATHER THAN IN COURT.  THIS AGREEMENT TO ARBITRATE IS INTENDED TO BE BROADLY INTERPRETED AND INCLUDES ANY DISPUTE, CLAIM, OR CONTROVERSY BETWEEN YOU AND COMPANY REGARDING ANY ASPECT OF YOUR RELATIONSHIP WITH US OR ANY CONDUCT OR FAILURE TO ACT ON OUR PART, INCLUDING CLAIMS BASED ON BREACH OF CONTRACT, TORT (FOR EXAMPLE, A NEGLIGENCE OR PRODUCT LIABILITY CLAIM), VIOLATION OF LAW OR ANY CLAIMS BASED ON ANY OTHER THEORY, AND INCLUDING THOSE BASED ON EVENTS THAT OCCURRED PRIOR TO THE DATE OF THIS AGREEMENT, WITH THE FOLLOWING EXCEPTIONS:
        <br />
        <br />
        <ul>
          <li>You may assert claims in your local small claims court if its rules permit it;</li>
          <li><u>Any claim regarding the validity, protection or enforcement of a party’s intellectual property rights (such as its patent, copyright, trademark, trade secret, or moral rights, but not including its privacy or publicity rights) must be brought in court; and</u></li>
          <li><u>In the event this agreement to arbitrate is for any reason held to be unenforceable, any dispute or claim against us (except for small-claims court actions) may be commenced only in a federal or state court located in Denver, Colorado, and we both consent to the jurisdiction of and venue in those courts for such purposes.  We both also consent to the jurisdiction of and venue in those courts for purposes of any claim regarding the validity, protection or enforcement of a party’s intellectual property rights (excluding its privacy or publicity rights). You agree to waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</u></li>
        </ul>
        <br />
        <br />
        This <u>agreement to arbitrate</u> is governed by the Federal Arbitration Act, including its procedural provisions, in all respects.
        <br />
        <br />
        15.2. <u>Prohibition of Class Actions and Non-Individualized Relief.  ANY ARBITRATION WILL BE CONDUCTED BY THE PARTIES IN THEIR INDIVIDUAL CAPACITIES ONLY AND NOT AS A CLASS ACTION OR OTHER REPRESENTATIVE ACTION. UNLESS BOTH YOU AND WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, CLASS, OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S).</u> If this specific provision is found to be unenforceable, then all of Section 15, other than subsection 15.5, will be null and void and neither of us will be entitled to arbitrate our dispute.
        <br />
        <br />
        (a)   <u>Arbitration Rules.</u>  Except as provided in this Section and to the fullest extent permitted by applicable law, all disputes arising under the Event Contract or the subject matter hereof shall be settled by binding arbitration in Denver, Colorado under the auspices of the Judicial Arbitration and Mediation Services in accordance with its Comprehensive Arbitration Rules and Procedures in effect at the time the claim or dispute is arbitrated (the “JAMS Rules”). The arbitration is to be conducted by one arbitrator having reasonable experience in restaurant matters, independent of the parties, selected in accordance with the JAMS Rules.  To the maximum extent permitted by law, the decision of the arbitrator will be final and binding on the parties thereto and not be subject to appeal.  All upfront fees, expenses, and costs of the arbitration incurred prior to the final decision of the arbitrator will be borne equally by the parties thereto and paid equally as incurred; provided, however, that the ultimate responsibility of such fees, costs expenses will be in inverse proportion to each party’s respective success on the merits. The arbitrator will be asked to calculate the allocation of such fees, costs, and expenses and such calculation will be final and binding on the parties thereto. The parties to any such arbitration agree that all facts and information relating to or provided in connection with any arbitration arising under the Event Contract will be kept confidential to the greatest extent possible under applicable law. Each of Patron and Casa Bonita agrees that, to the fullest extent allowed by law, the agreement to arbitrate pursuant to this paragraph constitutes a waiver of the parties’ right to a jury trial with respect to the claims subject to this paragraph.
        <br />
        <br />
        15.3. <strong><u>OPT-OUT PROCEDURE.</u> IF YOU DO NOT WISH TO BE BOUND BY THE ARBITRATION PROVISIONS IN THIS SECTION 15, YOU MUST NOTIFY COMPANY IN WRITING WITHIN 30 DAYS OF THE DATE THAT YOU ACCEPT THIS AGREEMENT FOR THE FIRST TIME.  YOU MAY OPT OUT BY EMAILING A WRITTEN NOTICE TO COMPANY. YOUR WRITTEN NOTIFICATION TO COMPANY MUST INCLUDE YOUR NAME, MAILING ADDRESS, E-MAIL ADDRESS, AND PHONE NUMBER AS WELL AS A CLEAR STATEMENT THAT YOU DO NOT WISH TO RESOLVE DISPUTES WITH COMPANY THROUGH ARBITRATION.  IF YOU OPT OUT OF THE ARBITRATION PROVISIONS IN THIS SECTION 15, ALL OTHER PROVISIONS OF THIS AGREEMENT WILL CONTINUE TO APPLY, INCLUDING SECTION 15.5, BELOW.</strong>
        <br />
        <br />
        15.4. <u>Amendment to Arbitration Provisions.</u> Notwithstanding any provision in this Agreement to the contrary, you and we agree that if we make any amendment to the arbitration provisions in this Section 15 in the future (other than an amendment to any notice address or site link provided herein), that amendment shall not apply to any claim that was filed in a legal proceeding against Company prior to the effective date of the amendment. The amendment shall apply to all other disputes or claims that have arisen or may arise between you and Company. We will notify you of amendments to the arbitration provisions of this Section 15 by posting the amended terms on the Site, and/or by providing notice to you by email, at least 30 days before the effective date of the amendments. If you do not agree to the amended terms, you may close your account with Company within the 30-day period and you will not be bound by the amended terms.
        <br />
        <br />
        15.5.  <u>JURY TRIAL AND CLASS ACTION WAIVER.</u> IF FOR ANY REASON A CLAIM PROCEEDS IN COURT RATHER THAN IN ARBITRATION, YOU AND COMPANY EACH UNCONDITIONALLY WAIVE ANY RIGHT TO A JURY TRIAL.  THIS MEANS THAT ANY CLAIM WOULD BE DECIDED BY A JUDGE, NOT A JURY.  FURTHER, WITH RESPECT TO ANY CLAIM THAT PROCEEDS IN A COURT, YOU AND WE AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING.
      </Section>
      <Section>
        <strong>16.  Miscellaneous.</strong>
        <br />
        <br />
        16.1.   <u>Geographic Restrictions.</u> Company is based in the State of Colorado in the United States. We make no claims that the Services or the Content are accessible or appropriate outside of the United States. Access to and use of the Services may not be legal by certain persons or in certain countries. If you access the Services from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
        <br />
        <br />
        16.2.   <u>Governing Law; Jurisdiction and Venue.</u> These Terms of Use and any dispute or claim arising out of or related to these Terms of Use, their subject matter, or their formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the State of Colorado, other than such laws and case law that would result in the application of the laws of a jurisdiction other than the State of Colorado. 
        <br />
        <br />
        16.3.   <u>Limitation on Time to File Claims.</u> ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SERVICES (INCLUDING THE CONTENT) MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION OR CLAIM ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        <br />
        <br />
        16.4.   <u>Waiver and Severability.</u> Our failure to exercise or enforce any right or provision of these Terms of Use will not constitute a waiver of such right or provision.  If any provision of these Terms of Use is held by a court of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent possible, and the remaining provisions of these Terms of Use will continue in full force and effect.
        <br />
        <br />
        16.5.   <u>Entire Agreement.</u> These Terms of Use, including our Privacy Policy, Casa Card Terms and Conditions and all other policies located on the Site constitute the sole and entire agreement between you and Company with respect to the subject matter hereof, and supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.
      </Section>
      <Section>
        <strong>17.  Questions.</strong>
        <p>If you have any questions about the Services or these Terms of Use, please email us at info@casabonitadenver.com.</p>
      </Section>
    </Root>
    <Footer />
  </div>
)