import React from 'react';
import {ItemDetails, ItemGroupContainer, ItemName, ItemPrice, Allergy} from "./item-group.styles";

function ItemGroup({ item_group, section_price }) {

    function formatDescription(description) {
        const partsWithBr = description.split('/n').flatMap((part, index) => index > 0 ? ['/n', part] : part);
        return partsWithBr.map((part, index) => {
            if (part === '/n') {
                return <br key={index} />;
            }

            const partsWithSpan = part.split(/(\(s\)|\(tn\))/g).map((partInner, indexInner) => (
                partInner === '(s)' || partInner === '(tn)'
                    ? <Allergy key={index + '-' + indexInner}>{partInner}</Allergy>
                    : partInner
            ));

            return partsWithSpan;
        });
    }

    return (
        <div>
            {item_group.map((item, index) => {

                if (!item.in_stock || item.in_stock.length === 0) {
                    // Skip this iteration if in_stock is empty
                    return null;
                }
                return (
                <ItemGroupContainer>
                    <ItemName>{item.item_name} {section_price == "" && item.item_price && `- $${item.item_price}`}</ItemName>
                    <ItemDetails>{formatDescription(item.item_description)}</ItemDetails>
                </ItemGroupContainer>
                );
            })}
        </div>
    );
};

export default ItemGroup;