import React, {useEffect, useRef} from "react";
import {
    Container,
    Box,
    Form,
    SuccessMessage,
    ErrorSection,
    ErrorText, InputColumn, ErrorWrapper, FormInputs, Title, TopBottom, ButtonInside
} from "./newsletter.styles";
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import validator from "validator";
import axios from "axios";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {REACT_APP_API_URL} from "../../core/constants";

export default function Newsletter({ children, width = '', ...props }) {

    //read props
    const { type } = props;
    const [isPress, setIsPress] = useState(type === 'press')
    const [utms, setUtms] = useState({});

    const source = {
        'homepage': 'website_homepage',
        'press': 'website_press',
        'newsletter': 'website_newsletter',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required."),
        firstName: isPress ? Yup.string().required("First Name is required.").min(2).max(50)
            : Yup.string().notRequired(),
        lastName: isPress ? Yup.string().required("Last name is required.").min(2).max(50)
            : Yup.string().notRequired(),
        media_outlet: isPress ? Yup.string().required("Media Outlet is required.").min(2).max(100)
            : Yup.string().notRequired(),
    });


    const formOptions = { resolver: yupResolver(validationSchema, { context: { type: type } }) };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm(formOptions)

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const submitHandler = handleSubmit(async (data) => {
        setLoading(true)
        let attributes = {}
        if (type === "press") {
            attributes = {
                email: data.email,
                "first_name": data.firstName,
                "last_name": data.lastName,
            }
        } else if (type === "homepage") {
            attributes = {
                email: data.email,
            }
        } else if (type === "newsletter") {
            attributes = {
                email: data.email
            }
        }

        let params = {
            "data" : {
                "type": "subscription",
                "attributes": {
                    "custom_source": source[type],
                    "profile": {
                        "data": {
                            "type": "profile",
                            "attributes": attributes
                        }
                    }
                },
                "relationships": {
                    "list": {
                        "data": {
                            "type": "list",
                            "id": "SdPCQF"
                        }
                    }
                }
            }
        }

        fetch(`https://a.klaviyo.com/client/subscriptions?company_id=WkVYqE`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'revision': '2024-05-15' // Adding the custom header
            },
            body: JSON.stringify(params),
        })
            .then(async response => {
                setSuccess(true)
            })
            .catch(error => {
                const message = 'Something went wrong. Please try again later.'
                setError('root', {
                    type: 'submit',
                    message,
                })
            });


        setLoading(false)
    })

    useEffect(() => {
        // Get the current URL
        const url = window.location.href;

        // Create a URL object and get its search params
        const urlObject = new URL(url);
        const params = new URLSearchParams(urlObject.search);

        // List of UTM parameters to check
        const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id'];
        let foundUtms = {};

        // Loop through and see which UTM parameters are present
        for (const param of utmParameters) {
            if (params.has(param)) {
                foundUtms[param] = params.get(param);
            }
        }

        setUtms(foundUtms);
    }, []);

    return (
        <Container>
            <Box>
                <TopBottom><div className="top"></div></TopBottom>
                <h2>
                    {type === "press" && "Sign up for the Press Kit"}
                    {(type === "homepage") && <Title>Sign up here to be eligible for tickets and for news and other updates from Casa Bonita.</Title>}
                    {(type === "newsletter") && <Title>Sign up here to be eligible for tickets and for news and other updates from Casa Bonita.</Title>}
                </h2>
                <form onSubmit={handleSubmit(submitHandler, console.error)}>
                    <Form type={type}>
                        <FormInputs type={type} className="inputs">
                            {type === "press" &&
                                <InputColumn type={type}>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        {...register('firstName')}
                                    />
                                    <ErrorWrapper>
                                        <ErrorMessage
                                            index="firstName"
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <ErrorText>{message}</ErrorText>}
                                        />
                                    </ErrorWrapper>
                                </InputColumn>

                            }
                            {type === "press" &&
                                <InputColumn type={type}>
                                    <input type="text" placeholder="Last Name" {...register('lastName')} />
                                    <ErrorWrapper>
                                        <ErrorMessage
                                            index="lastName"
                                            errors={errors}
                                            name="lastName"
                                            render={({ message }) => <ErrorText>{message}</ErrorText>}
                                        />
                                    </ErrorWrapper>
                                </InputColumn>
                            }
                            <InputColumn type={type}>
                                <input
                                    type="text"
                                    placeholder="Email Address"
                                    {...register('email')}
                                />
                                <ErrorWrapper>
                                    <ErrorMessage
                                        index="email"
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <ErrorText>{message}</ErrorText>}
                                    />
                                </ErrorWrapper>
                            </InputColumn>
                            {type === "press" &&
                                <InputColumn type={type}>
                                    <input type="text" placeholder="Media Outlet" {...register('media_outlet')} />
                                    <ErrorWrapper>
                                        <ErrorMessage
                                            index="media_outlet"
                                            errors={errors}
                                            name="media_outlet"
                                            render={({ message }) => <ErrorText>{message}</ErrorText>}
                                        />
                                    </ErrorWrapper>
                                </InputColumn>
                            }
                            <button><ButtonInside>Submit</ButtonInside></button>
                        </FormInputs>
                    </Form>
                </form>
                {success && <SuccessMessage>Please check your inbox for confirmation email.</SuccessMessage>}
                <TopBottom><div className="botton"></div></TopBottom>
            </Box>
        </Container>
    )
}
export { Newsletter }
