import styled, {css} from "styled-components";
import {mediaDesktop, mediaMobile, mediaTablet} from "../../utils/media-queries";

export const MainParent = styled.div`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  
`;

export const Main = styled.div`
  
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  padding: 1rem 1rem 0px;
  z-index: 100;
  background: #ffffd9;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  ${mediaDesktop} {
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 4.5rem 3.3rem 0px;
    gap: 3.6rem;
  }

  ${props => {
    if (props.scrollPosition > 0) return css`
      ${mediaDesktop} {
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding: 1.5rem 3.3rem 1rem;
        gap: 3.6rem;
      }
    `;
  }}
`;

export const Menu = styled.div`
  font-family: "new-spirit",serif;
  background: #121212;
  color: #ffffd9;
  text-transform: uppercase;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-size: 1.7rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  cursor: pointer;
  ${mediaDesktop} {
    background: #2d3151;
    font-size: 2.6rem;
    border-radius: 0.7rem;
    padding: 0.6rem;
    max-width: 20rem;
    min-width: 10rem;
  }
  ${mediaMobile} {
    background: #121212;
    font-size: 1.7rem;
    border-radius: 0.4rem;
    padding: 0.5rem;
    max-width: 10rem;
  }

`;
export const MenuContent = styled.div`
  border: 0.2rem solid var(--cream);
  padding: 0.2rem 0.5rem;
  display: block;
  border-radius: 0.4rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  background-image: url(/images/burger.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  ${mediaDesktop} {
    border: 0.4rem solid #ffffd9;
    border-radius: 0.7rem;
  }
  ${mediaMobile} {
    border: 0.2rem solid #ffffd9;
    border-radius: 0.4rem;
  }
`;

export const TopNavItem = styled.div`
  cursor: pointer;
  font-family: "new-spirit",serif;;
  background: #121212;;
  color: #ffffd9;
  text-transform: uppercase;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-size: 1.7rem;
  display: flex;
  transition: all 0.3s ease 0s;
  -webkit-box-flex: 1;
  flex-grow: 1;
  ${mediaDesktop} {
    background: #2d3151;;
    font-size: 2.6rem;
    border-radius: 0.7rem;
    padding: 0.6rem;
  }
  
  ${mediaMobile} {
    display: none;
  }
`;

export const TopNavContent = styled.a`
  border: 0.2rem solid #ffffd9;
  padding: 0.2rem 0.5rem;
  display: block;
  border-radius: 0.4rem;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #FFFFD9;
  ${mediaDesktop} {
    border: 0.4rem solid #ffffd9;
    border-radius: 0.7rem;
  }
`;

export const SplitMenu = styled(TopNavItem)`
`;

export const SplitMenuContent = styled(TopNavContent)`
`;


export const Address = styled(TopNavItem)`
`;

export const AddressContent = styled(TopNavContent)`
`;

export const Times = styled(TopNavItem)`
`;

export const TimesContent = styled(TopNavContent)`
`;

export const Booking = styled.div`

  cursor: pointer;
  font-family: "new-spirit",serif;;
  background: #B24538;;
  color: #ffffd9;
  text-transform: uppercase;
  border-radius: 0.4rem;
  padding: 0.5rem;
  font-size: 1.7rem;
  display: flex;
  transition: all 0.3s ease 0s;
  -webkit-box-flex: 1;
  flex-grow: 1;
  ${mediaDesktop} {
    background: #B24538;;
    font-size: 2.6rem;
    border-radius: 0.7rem;
    padding: 0.6rem;
  }

  ${mediaMobile} {
    display: none;
  }
  
  ${mediaMobile} {
    display: block;
    gap: 4.1rem;
    margin-left:20px;
  }

 
    
  
`;

export const BookingContent = styled(TopNavContent)`
  ${mediaMobile} {

    width:auto;
  }
`;

export const DropDown = styled.div`
  visibility: ${props => props.isVisable};
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 40%;
  display: flex;
  flex-direction: column;
  background: #ffffd9;
  padding: 7rem 1rem 1.5rem;
  gap: 1.1rem;
  z-index: 90;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: auto;
  overflow-y: auto;
  ${mediaDesktop} {
    padding: 19.5rem 2rem 2rem;
    gap: 2.6rem;
    border-radius: 0px 0px 20px 0px;
  }
  ${mediaTablet} {
    border-radius: 0px 0px 20px 0px;
    width: 50%;
  }
  ${mediaMobile} {
    border-radius: 0px 0px 0px 0px;
    width: 100%;
    padding-bottom: 100vh;
  }
`;

export const NavLinks = styled.a`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: inherit;
  text-decoration: none;
`;

export const NavLinkContents = styled.a`
  width: auto;
  flex: 1 1 auto;

  font-family: "new-spirit",serif;
  color: #ffffd9;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 2.4rem;
  display: flex;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  background: linear-gradient(${props => props.colorStart} 0%, ${props => props.colorEnd} 100%), ${props => props.colorEnd};
  transition: color 0.3s ease 0s, background 0.3s ease 0s;

  ${mediaDesktop} {
    padding: 0.6rem;
    font-size: 5.8rem;
    border-radius: 2rem;
  }
`;

export const NavContent = styled.div`
  border: 0.3rem solid currentcolor;
  padding: 4rem 5.6rem;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  transition: border 0.3s ease 0s;
  ${mediaDesktop} {
    border: 0.4rem solid currentcolor;
    border-radius: 2rem;
    padding: 1rem;
  }
  
  ${mediaMobile} {
    padding: 2rem 2rem;
  }
`;

export const HomeLink = styled(NavLinks)`
`;
export const HomeLinkContent = styled(NavLinkContents)`
`;
export const HomeContent = styled(NavContent)`
`;

export const TicketLink = styled(NavLinks)`
`;
export const TicketLinkContent = styled(NavLinkContents)`
`;
export const TicketContent = styled(NavContent)`
`;

export const MenuPageLink = styled(NavLinks)`
`;
export const MenuPageLinkContent = styled(NavLinkContents)`
`;
export const MenuPageContent = styled(NavContent)`
`;


export const ElMercadoLink = styled(NavLinks)`
`;
export const ElMercadoLinkContent = styled(NavLinkContents)`
`;
export const ElMercadoContent = styled(NavContent)`
`;

export const GiftCardsLink = styled(NavLinks)`
`;
export const GiftCardsLinkContent = styled(NavLinkContents)`
`;
export const GiftCardsContent = styled(NavContent)`
`;

export const CareersLink = styled(NavLinks)`
`;
export const CareersLinkContent = styled(NavLinkContents)`
`;
export const CareersContent = styled(NavContent)`
`;

export const PrivacyLink = styled(NavLinks)`
`;
export const PrivacyLinkContent = styled(NavLinkContents)`
`;
export const PrivacyContent = styled(NavContent)`
`;

export const FAQsLink = styled(NavLinks)`
`;
export const FAQsLinkContent = styled(NavLinkContents)`
`;
export const FAQsContent = styled(NavContent)`
`;
