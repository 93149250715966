import React from 'react'
import Menu from '../components/menu/menu'
import Welcome from '../components/welcome/welcome'
import Meals from '../components/meals/meals'
import GiftCards from '../components/giftcards/giftcards'
import Footer from '../components/footer/footer'
import Spacing from '../components/spacing/spacing'
import PhotoGallery from '../components/photogallery/photogallery'
/*import { QuotesGrid } from '../components/quotes-grid'*/
import Newsletter from '../components/newsletter/newsletter'
import Video from '../components/video/video'
import MealPhoto from '../components/mealphoto/mealphoto'
import SplitMenu from '../components/splitmenu/splitmenu'
import Mercado from "../components/merchado/mercado";
import Callout from "../components/callout/callout";

function Home() {
  return (
    <div className='App'>
        <Menu />
        <Spacing />
        <Callout />
        <Newsletter type="homepage"/>
        <Welcome />
        <SplitMenu pageType="homepage" />
        <Mercado />
        <PhotoGallery />
        <Footer></Footer>
    </div>
  )
}

export default Home
