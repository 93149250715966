import React from 'react';
import {SectionNoPriceContainer, SectionNoPriceImage} from "./section-no-price.styles";


function SectionNoPrice({ price }) {
    return (
        <SectionNoPriceContainer>
            <SectionNoPriceImage src="/images/table-menus/section-no-price.png" />
        </SectionNoPriceContainer>
    );
};

export default SectionNoPrice;