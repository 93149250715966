import { useState, useEffect } from 'react'
import { useBreakpoint } from '../../hooks'
import { Root, MenuImage, MenuButton } from './splitmenu.styles'
import { BREAKPOINT_MENU_CONFIG } from './splitmenu.constants'

const SplitMenu = ({pageType}) => {
  const breakpoint = useBreakpoint()
  const [menuConfig, setMenuConfig] = useState(null)
  const FILTER_CRITERIA = {
    homepage: {
      xs: [0, 1, 2],
      sm: [0, 1, 2],
      md: [0, 1, 2],
      lg: [0, 1],
      xl: [0, 1],
    },
    menu: {
      xs: [3, 4],
      sm: [3, 4],
      md: [3, 4],
      lg: [2, 3],
      xl: [2, 3],
    },
  };

  useEffect(() => {
    if (breakpoint == null) { return; }

    const config = BREAKPOINT_MENU_CONFIG[breakpoint];

    const getMenuBlocks = (breakpoint, pageType) => {
      const indices = FILTER_CRITERIA[pageType][breakpoint];
      return config.menuBlocks.filter((_, index) => indices.includes(index));
    };

    const filteredMenuBlocks = getMenuBlocks(breakpoint, pageType);
    config['menuBlocks'] = filteredMenuBlocks;
    setMenuConfig(config);

  }, [breakpoint])

  if (!menuConfig) return null

  return (
    <Root aria-label='Meals'>
      {Array.isArray(menuConfig.menuBlocks) &&
        menuConfig.menuBlocks.map((block, index) => (
          <MenuImage key={block} src={block} alt={`Food Menu Block ${index}`} />
        ))}
      {menuConfig.buttons.map((button, index) => (
        <MenuButton
          key={index}
          {...button}
          href='/tickets'
          role='link'
          aria-label='Click here to Get Tickets'
        />
      ))}
    </Root>
  )
}

export default SplitMenu
