import { React, useState } from 'react';
import styled from 'styled-components';
import Footer from '../../components/footer/footer'
import Menu from '../../components/menu/menu'
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';
import ContactForm from './contactform';

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-family: 'Volkhov', serif;
  margin: 20rem 30rem 10rem;
  ${mediaTablet} {
    margin: 15rem 13rem 8rem;
  }
  ${mediaMobile} {    
    margin: 6rem 4rem 2rem;
  }
  ${mediaSmallMobile} {
    margin: 6rem 2rem 2rem;
  }
`

const Question = styled.h2`
  margin-top: 4rem;
  margin-bottom: 0; 
  color: #af443a;
  font-size: 4rem;
  ${mediaTablet} {
    font-size: 4rem;
  }
  ${mediaMobile} {  
    font-size: 1.8rem;  
    margin-top: 2rem;
  }
  ${mediaSmallMobile} {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
`
const Answer = styled.div`
  font-size: 3.5rem;
  color: #282824;
  margin-bottom: 3rem;
  ${mediaTablet} {
    font-size: 3.5rem;
  }
  ${mediaMobile} {    
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  ${mediaSmallMobile} {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`

function ContactMain({ onContactUsClick }) {
  return (
    <div>
      <Menu />
      <Box>
        <Question>When is Casa Bonita opening?</Question>
        <Answer>While in the beta-testing phase, Casa Bonita will be opening with limited dinner hours. The first guests will be pulled EXCLUSIVELY from the email list. No walk-ins. Sign up for the {' '}
          <a href="/newsletter" target="_blank" rel="noreferrer">Email List</a> now.</Answer>
        <Question>Does Casa Bonita take walk-ins or reservations?</Question>
        <Answer>
          Casa Bonita is now operating under a ticketed system and does not accept walk-ins. To be alerted when tickets are available, please sign up for the Casa Bonita {' '}
          <a href="/newsletter" target="_blank" rel="noreferrer">Email List</a>.
        </Answer>
        <Question>How do I sign up for the Casa Bonita email list?</Question>
        <Answer>
          To subscribe to the Casa Bonita email list, click {' '}
          <a href="/newsletter" target="_blank" rel="noreferrer">Here</a> and enter your email. You will receive an email asking you to please confirm your subscription - click on the pink “Yes, I Want to Subscribe” button.
        </Answer>
        <Question>Can Casa Bonita donate to my fundraiser?</Question>
        <Answer>
          Casa Bonita is accepting applications for donation requests. Please{' '}
          <a href="javascript:void( window.open( 'https://form.jotform.com/231308038163045', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) ">fill out the Application</a> and someone will get back to you.
        </Answer>
        <Question>Is Casa Bonita hiring?</Question>
        <Answer>
          Open jobs at Casa Bonita are listed on the{' '}
          <a href="/careers" target="_blank" rel="noreferrer">Career Section</a>. Please apply directly on the job page.
        </Answer>
        <Question>For all other inquiries</Question>
        <Answer>
        <div style={{ color: '#0100EE', textDecoration: 'underline', cursor: 'pointer' }} onClick={onContactUsClick}>Contact Us 
        </div>
        </Answer>
      </Box>
      <Footer />
    </div>
  )
}

function ContactPage() {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleContactUsClick = () => {
    setShowContactForm(true);
  };

  return (
    <div>
    {showContactForm ? (
    <ContactForm />
    ) : (
    <ContactMain onContactUsClick={handleContactUsClick} />
    )}
    </div>
  )
}

export default ContactPage;