import React, { useEffect, useState } from 'react';
import {Bottom, DrinksTitle, Main, Top} from "./drinks-menu-styled";
import { fetchMenuData, parseMenuData } from './menuAPI';
import Section from "../../components/table-menus/sections";

function DrinksMenu() {

    const [menuData, setMenuData] = useState([]);
    useEffect(() => {
        fetchMenuData()
            .then(rawData => parseMenuData(rawData))
            .then(menuData => setMenuData(menuData))
            .catch(error => console.error(error));
    }, []);

    return (
        <Main>
            <Top src="/images/table-menus/top-v2.png" />
            <DrinksTitle src="/images/table-menus/drinks-title.png" />
            {menuData.map((thisMenuData, index) => (
                <Section section_name={thisMenuData.section_name} section_price={thisMenuData.section_price} section_description={thisMenuData.section_description} item_group={thisMenuData.item_group} />
            ))}
            <Bottom src="/images/table-menus/bottom.png" />
        </Main>
    );
}

export default DrinksMenu;