import styled from "styled-components";
import {mediaDesktop, mediaMobile, mediaSmallMobile, mediaTablet} from "../../utils/media-queries";


export const Spacer = styled.div`
    margin-bottom: 5rem;
    ${mediaDesktop} {
        margin-bottom: 5rem;
    }
    ${mediaTablet} {
        margin-bottom: 5rem;
    }
    ${mediaMobile} {
        margin-bottom: 2rem;
    }
    ${mediaSmallMobile} {
        margin-bottom: 1rem;
    }
`;

export const SectionDivider = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
    ${mediaDesktop} {
        padding-top: 20rem;
        padding-bottom: 20rem;
    }
    ${mediaTablet} {
        padding-top: 20rem;
        padding-bottom: 20rem;
    }
    ${mediaMobile} {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    ${mediaSmallMobile} {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
`;
export const SectionName = styled.div`
    text-align: center;
    font-family: Agbalumo;
    color: #AF4434;
    ${mediaDesktop} {
        font-size: 12vw;
    }
    ${mediaTablet} {
        font-size: 12vw;
    }
    ${mediaMobile} {
        font-size: 12vw;
    }
    ${mediaSmallMobile} {
        font-size: 12vw;
    }
`;

export const SectionDescription = styled.div`
    text-align: center;
    
    font-family: Josefin;
    ${mediaDesktop} {
        font-size: 5vw;
        color: #9e4b27;
        padding-bottom: 7rem;
    }
    ${mediaTablet} {
        font-size: 5vw;
        color: #9e4b27;
        padding-bottom: 7rem;
    }
    ${mediaMobile} {
        font-size: 5vw;
        color: #9e4b27;
        padding-bottom: 2rem;
    }
    ${mediaSmallMobile} {
        font-size: 5vw;
        color: #9e4b27;
        padding-bottom: 2rem;
    }
`;

