import React from 'react'
import styled from 'styled-components'
import { mediaMobile, mediaTablet } from '../../utils/media-queries'

const Main = styled.div`
  height: 55px;
  width: 100%;
  ${mediaTablet} {
    height: 74px;
  }
  ${mediaMobile} {
    height: 60px;
  }
`
function Spacing() {
  return <Main></Main>
}

export default Spacing
