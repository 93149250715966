import styled from 'styled-components'
import { media } from '../../utils/media-queries'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 5rem;

  ${media('xs')} {
    width: 90vw;
    margin-top: 5rem;
  }

  ${media('md')} {
    margin-top: 15rem;
    width: 85vw;
  }
`

export const Heading = styled.h3`
  text-align: center;
  color: var(--brown);
  font-family: new-spirit, serif;
  font-size: 4rem;

  ${media('xs')} {
    font-size: 3rem;
  }

  ${media('md')} {
    font-size: 7rem;
  }
`

export const Tickets = styled.h3`
  text-align: center;
  color: var(--brown);
  font-family: new-spirit, serif;
  font-size: 4rem;
  margin-bottom: 0;

  ${media('xs')} {
    font-size: 3rem;
  }

  ${media('md')} {
    font-size: 7rem;
  }
`

export const Revised = styled.div`
  border-top: 0.2rem solid #D8583D;
  color: #DA6951;
  font-size: 3rem;
  font-weight: bold;
  padding: 5rem 0;
  text-align: left;
  
  ${media('xs')} {
    padding: 2rem 0;
    font-size: 1.5rem;
  }
  ${media('md')} {
    font-size: 3rem;
  }
`

export const Section = styled.p`
  color: var(--brown);
  font-family: new-spirit, serif;
  margin: 1.5rem 0;

  ${media('xs')} {
    font-size: 1.5rem;
  }

  ${media('md')} {
    font-size: 3rem;
  }
`
