import styled from "styled-components";
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

export const Main = styled.div`
  background: url(/images/sliderBg.jpg) repeat-x;
  background-repeat: repeat-x;
  background-size: auto 100%;
  padding: 3rem 3rem;
  overflow: hidden;
  ${mediaDesktop} {
    padding: 7rem 0;
  }
`;

export const Slider = styled.div`
  max-width: 150rem;
  margin: 0 auto;
  position: relative;
  width: 100%;

  .swiper {
    overflow: visible;
  }
  .swiper-slide {
    opacity: 1;
  }
  .swiper-slide-visible .ImageContainer {
    transform: rotate(0);
    margin: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    width: 10rem;
    height: 14rem;
    background-size: 100%;
    left: 100%;
    top: 50%;
    margin-left: 10rem;
    margin-top: -7rem;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &:after {
      display: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .swiper-button-prev {
    left: unset;
    margin-left: unset;
    transform: scaleX(-1);
    right: 100%;
    margin-right: 10rem;
  }
  
`;

export const ImageContainer = styled.div`
  transition: all 0.5s;
  img {
    width: 50%;
    height: auto;
    pointer-events: none;
  }
  &.image0 {
    transform: rotate(-3deg);
    margin-top: 1.5rem;
    margin-left: -1rem;
  }
  &.image1 {
    transform: rotate(3deg);
    margin-top: -1.5rem;
    margin-left: -1rem;
  }
  &.image2 {
    transform: rotate(-4deg);
    margin-top: 0.5rem;
    margin-left: -2.5rem;
  }
  &.image3 {
    transform: rotate(7deg);
    margin-top: -0.5rem;
    margin-left: 1.5rem;
  }
  &.image4 {
    transform: rotate(2deg);
    margin-top: 1.5rem;
    margin-left: -1rem;
  }
  &.image5 {
    transform: rotate(-2deg);
    margin-top: -1rem;
    margin-left: 1rem;
  }
  &.image6 {
    transform: rotate(2deg);
    margin-top: 0.5rem;
    margin-left: 2rem;
  }
  ${mediaDesktop} {
    &.image0 {
      transform: rotate(-3deg);
      margin-top: 3rem;
      margin-left: -2rem;
    }
    &.image1 {
      transform: rotate(3deg);
      margin-top: -3rem;
      margin-left: -2rem;
    }
    &.image2 {
      transform: rotate(-4deg);
      margin-top: 1rem;
      margin-left: -5rem;
    }
    &.image3 {
      transform: rotate(7deg);
      margin-top: -1rem;
      margin-left: 3rem;
    }
    &.image4 {
      transform: rotate(2deg);
      margin-top: 3rem;
      margin-left: -2rem;
    }
    &.image5 {
      transform: rotate(-2deg);
      margin-top: -2rem;
      margin-left: 2rem;
    }
    &.image6 {
      transform: rotate(2deg);
      margin-top: 1rem;
      margin-left: 4rem;
    }
`;

export const Image = styled.img`

`;