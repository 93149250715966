import React from 'react';
import {
    SectionDescription,
    SectionDivider,
    SectionName, Spacer,
} from "./sections.styled";
import SectionPrice from "./section-price";
import ItemGroup from "./item-group";
import SectionNoPrice from "./section-no-price";

function Section({ section_name, section_price, section_description, item_group }) {
    return (
        <div>
            <SectionDivider src="/images/table-menus/section-divider.png" />
            <SectionName>{section_name}</SectionName>
            <SectionDescription>{section_description}</SectionDescription>
            {section_price ?
                <SectionPrice price={section_price} /> :
                <SectionNoPrice/>
            }
            <Spacer />
            <ItemGroup item_group={item_group} section_price={section_price} />
        </div>
    );
};

export default Section;