import React from 'react';
import styled from 'styled-components';
import {mediaDesktop, mediaTablet, mediaSmallMobile, mediaMobile} from '../../utils/media-queries';

const Main = styled.div`
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    padding: 1rem;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    ${mediaDesktop} {
      padding: 3.3rem;
    }
`;

const Container = styled.div`
    position: relative;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-bottom: 1rem;

  ${mediaDesktop} {
      margin-bottom: 0rem;
    }
`;
const ContainerFrame = styled.div`
  border: 1rem solid transparent;
  border-image-source: url(/images/main_border.svg);
  border-image-repeat: stretch;
  border-image-slice: 150 150;
  border-image-width: 12rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  z-index: 2;
  ${mediaDesktop} {
    border-width: 20rem;
    border-image-width: 30rem;
  }
  ${mediaMobile}{
    border-image-slice: 130 140;
  }
`;

const ContainerBackground = styled.div`
  background: linear-gradient(180deg, #322b3b 12.69%, #c64839 93.04%, #ef9848 105.33%),#d9d9d9;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  z-index: 1;
`;

const ContainerContent = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
  position: relative;
  padding: 5rem 2.5rem;
  ${mediaDesktop} {
    width: 100%;
    padding: 10rem;
  }
  ${mediaMobile} {
    width: 100%;
  }
`;

const ContentFrame = styled.div`
  width: 100%;
`;

const WelcomeLogo = styled.div`
  background: url(/images/welcome.svg) no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  aspect-ratio: 214/164;
  width: 21.4rem;
  margin: 0 auto;
  ${mediaDesktop} {
    aspect-ratio: 1109/301;
    max-width: 110rem;
    width: 100%;
    background: url(/images/welcome.svg) no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
  }
  ${mediaTablet} {
    aspect-ratio: 1109/301;
    max-width: 110rem;
    width: 100%;
    background: url(/images/welcome.svg) no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
  }
  ${mediaMobile} {
    aspect-ratio: 1109/301;
    max-width: 110rem;
    width: 100%;
    background: url(/images/welcome.svg) no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
  }
`;

const WelcomeText = styled.h1`
  color: #f3b24c;
  text-align: center;
  font-family: 'new-spirit',serif;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 82%;
  letter-spacing: -0.035em;
  line-height: 82%;
  ${mediaDesktop} {
    font-family: 'new-spirit',serif;
    font-size: 10.5rem;
    font-weight: 700;
    line-height: 82%;
    letter-spacing: -0.035em;
  }
`;

const WelcomeImages = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-width: 100%;

  ${mediaDesktop} {
    margin: -10rem auto 8rem;
    width: 80%;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
  }
  
  ${mediaMobile} {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  
  img {
    
    object-fit: cover;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: auto;
    width: 0;

    ${mediaMobile} {
      object-fit: cover;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      height: auto;
      width: 0;
    }
    
    &:nth-of-type(1) {
      
      aspect-ratio: 187/193;
      -webkit-flex: 1 1 183rem;
      -ms-flex: 1 1 183rem;
      flex: 1 1 183rem;

      ${mediaDesktop} {
        
        aspect-ratio: 470/358;
        -webkit-flex: 1 1 47rem;
        -ms-flex: 1 1 47rem;
        flex: 1 1 47rem;
        
      }
      
      ${mediaMobile} {
        aspect-ratio: 187/193;
        -webkit-flex: 1 1 183rem;
        -ms-flex: 1 1 183rem;
        flex: 1 1 183rem;
      }
    }

    &:nth-of-type(2) {

      aspect-ratio: 107/193;
      -webkit-flex: 1 1 107rem;
      -ms-flex: 1 1 107rem;
      flex: 1 1 107rem;

      ${mediaDesktop} {

        aspect-ratio: 518/257;
        -webkit-flex: 1 1 51rem;
        -ms-flex: 1 1 51rem;
        flex: 1 1 51rem;

      }
      
      ${mediaMobile} {
        aspect-ratio: 107/193;
        -webkit-flex: 1 1 107rem;
        -ms-flex: 1 1 107rem;
        flex: 1 1 107rem;
      }
    }

    &:nth-of-type(3) {
      ${mediaDesktop} {
        aspect-ratio: 337/257;
        -webkit-flex: 1 1 33rem;
        -ms-flex: 1 1 33rem;
        flex: 1 1 33rem;
      }
      ${mediaMobile} {
        display: none;
      }
    }

    &:nth-of-type(4) {
      ${mediaDesktop} {
        aspect-ratio: 269/375;
        -webkit-flex: 1 1 26rem;
        -ms-flex: 1 1 26rem;
        flex: 1 1 26rem;
      }
      ${mediaMobile} {
        display: none;
      }
    }

    &:nth-of-type(5) {
      ${mediaDesktop} {
        aspect-ratio: 199/460;
        -webkit-flex: 1 1 19rem;
        -ms-flex: 1 1 19rem;
        flex: 1 1 19rem;
      }
      ${mediaMobile} {
        display: none;
      }
    
    }
    
  }

`;

const WelcomeAddress = styled.div`
  text-align: center;
  color: #121212;
  line-height: 1;
  font-family: 'new-spirit',serif;
  font-size: 4rem;
  font-weight: 600;
  line-height: 82%;
  letter-spacing: -0.035em;
  font-size: 1.7rem;
  font-weight: 700;
  ${mediaDesktop} {
    font-family: 'new-spirit',serif;
    font-size: 4rem;
    font-weight: 600;
    line-height: 82%;
    letter-spacing: -0.035em;
  }
  ${mediaTablet} {
    font-family: 'new-spirit',serif;
    font-size: 4rem;
    font-weight: 600;
    line-height: 82%;
    letter-spacing: -0.035em;
  }
  ${mediaMobile} {
    font-family: 'new-spirit',serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.035em;
  }
  
`;

const BottomText = styled.div`
  color: #000000;
  text-align: center;
  font-family: "Volkhov", serif;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.035em;
  line-height: 82%;
  ${mediaDesktop} {
    font-size: 9rem;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.035em;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  ${mediaTablet} {
    font-size: 8rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.035em;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  ${mediaMobile} {
    font-size: 3rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.035em;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
`;


export default class Welcome extends React.Component {

    render() {
        return (
            <Main>
                <Container>
                    <ContainerFrame></ContainerFrame>
                    <ContainerBackground></ContainerBackground>
                    <ContainerContent>
                        <ContentFrame>
                            <WelcomeLogo></WelcomeLogo>
                            <WelcomeText>The Greatest Restaurant<br/>in the World!</WelcomeText>
                            <WelcomeImages>
                                <img alt="Intro Banner Image" loading="lazy" width="500" height="500" decoding="async"
                                     src={process.env.PUBLIC_URL +"/images/Image-1b.jpg"} />
                                <img alt="Intro Banner Image" loading="lazy" width="500" height="500" decoding="async"
                                     src={process.env.PUBLIC_URL +"/images/Image-2c.jpg"} />
                                <img alt="Intro Banner Image" loading="lazy" width="500" height="500" decoding="async"
                                     src={process.env.PUBLIC_URL +"/images/Image-3b.jpg"} />
                                <img alt="Intro Banner Image" loading="lazy" width="500" height="500" decoding="async"
                                     src={process.env.PUBLIC_URL +"/images/Image-4a.jpg"} />
                                <img alt="Intro Banner Image" loading="lazy" width="500" height="500" decoding="async"
                                     src={process.env.PUBLIC_URL +"/images/Image-5a.jpg"} />
                            </WelcomeImages>
                            <WelcomeAddress>6715 W Colfax Avenue, Lakewood, CO 80214 </WelcomeAddress>
                        </ContentFrame>
                    </ContainerContent>
                </Container>
            </Main>
        );
    }
}
